import { PSFiltersType } from "./PrecedentSearch";
import { FiltersResponseType, KeyValueType } from "../../utils/types";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { getSearchFilters } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { FiltersModal } from "./FiltersModal";
import { Button, Segmented, Tag } from "antd";
import {
  CalendarOutlined,
  CheckOutlined,
  FileProtectOutlined,
  MinusOutlined,
  SearchOutlined,
  ShopOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { arrayToMapConversion, removeItemFromArray } from "../../utils/utils";
import { equals, isNil, omit, path } from "ramda";
import { convertDateToFormat } from "../../utils/moment";
import { FiltersDetails } from "./FiltersDetails";
import {
  getDefaultPrecedentFilters,
  mapResponses,
  mapResponsesReverse,
} from "../../utils/precedent";
import { DANGER_BUTTON_STYLE } from "../../utils/cssConfigs";
import { PETransactionTagType } from "../../utils/enums";
import { UserContext } from "../../context/UserContext";

export const IndustryTag: FC<IndustryTagType> = ({ name, id, onClose }) => {
  return (
    <Tag
      color={"geekblue"}
      icon={<ShopOutlined />}
      className={"select-none mt-2"}
      onClose={onClose}
      id={id}
      closable={!isNil(onClose)}
    >
      {name}
    </Tag>
  );
};
export const CompanyTag: FC<CompanyTagType> = ({ id, onClose, name }) => {
  return (
    <Tag
      color={"cyan"}
      icon={<UserOutlined />}
      className={"select-none mt-2"}
      onClose={onClose}
      id={id}
      closable={!isNil(onClose)}
    >
      {name}
    </Tag>
  );
};
export const TextTag: FC<TextTagType> = ({ value, onClose }) => {
  return (
    <Tag
      color={"warning"}
      icon={<SearchOutlined />}
      className={"select-none mt-2"}
      onClose={onClose}
      closable={!isNil(onClose)}
    >
      {value}
    </Tag>
  );
};
export const DateTag: FC<DateTagType> = ({
  date,
  onClose,
  startDate = true,
}) => {
  return (
    <Tag
      color={"blue"}
      icon={<CalendarOutlined />}
      className={"select-none mt-2"}
      onClose={onClose}
      closable={!isNil(onClose)}
    >
      {startDate ? "From" : "To"}: {convertDateToFormat(date, "DD MMM YYYY")}
    </Tag>
  );
};
export const VersionTag: FC<VersionTagType> = ({ value }) => {
  return (
    <Tag
      color={"success"}
      className={"select-none mt-2"}
      icon={<FileProtectOutlined />}
    >
      {value}
    </Tag>
  );
};
export const ResponseTag: FC<ResponseTagType> = ({ value }) => {
  return (
    <Tag
      color={"success"}
      className={"select-none mt-2"}
      icon={<CheckOutlined />}
    >
      {mapResponsesReverse(value)}
    </Tag>
  );
};
export const StatusTag: FC<StatusTagType> = ({ name, id, onClose }) => {
  return (
    <Tag
      color={"geekblue"}
      // icon={<ShopOutlined />}
      className={"select-none mt-2"}
      onClose={onClose}
      id={id}
      closable={!isNil(onClose)}
    >
      {name}
    </Tag>
  );
};
export const PrecedentSearchFilters: FC<PrecedentSearchFiltersType> = ({
  selectedFilters,
  onChange,
}) => {
  const { user } = useContext(UserContext);

  const [filters, setFilters] = useState<FiltersResponseType>({
    industries: [],
    versionFilters: [],
    companies: [],
    statuses: [],
  });
  const [categorisedFilters, setCategorizedFilters] =
    useState<CategorisedFilterType>({ industry: {}, company: {}, version: {} });

  const DEFAULT_PRECEDENT_FILTERS = useMemo(() => {
    return getDefaultPrecedentFilters(user?.elementPermissions);
  }, [user]);

  const fetchSearchFilters = (): void => {
    getSearchFilters({})
      .then(({ data }: ResponseType<FiltersResponseType>) => {
        setFilters(data);
        setCategorizedFilters({
          industry: arrayToMapConversion(data.industries, "id"),
          company: arrayToMapConversion(data.companies, "id"),
          version: arrayToMapConversion(data.versionFilters, "id"),
        });
      })
      .catch(console.error);
  };

  const parseFilters = (values: PSFiltersType, defaults = true): void => {
    onChange({
      ...values,
      size: defaults ? selectedFilters.size : values.size,
      pageToken: defaults
        ? selectedFilters?.pageToken ?? null
        : values.pageToken,
      fromTimestamp: values?.fromTimestamp ?? null,
      toTimestamp: values?.toTimestamp ?? null,
    });
  };

  useEffect(() => {
    const search = location.search;
    const fromTimestamp =
      new URLSearchParams(search)?.get("fromTimestamp") ?? null;
    const toTimestamp = new URLSearchParams(search)?.get("toTimestamp") ?? null;

    const filters: PSFiltersType = {
      size: Number(new URLSearchParams(search)?.get("size") ?? 25),
      companyIds: new URLSearchParams(search).getAll("companyIds") ?? [],
      fromTimestamp: fromTimestamp ? Number(fromTimestamp) : null,
      toTimestamp: fromTimestamp ? Number(toTimestamp) : null,
      pageToken: new URLSearchParams(search)?.get("pageToken") ?? null,
      industryIds: new URLSearchParams(search).getAll("industryIds") ?? [],
      versions: new URLSearchParams(search).get("versions") ?? "LAST",
      lenderPublished: mapResponses(
        new URLSearchParams(search)?.get("lenderPublished") ?? "RESPONDED"
      ),
      listType: new URLSearchParams(search).get("listType") ?? "ALL",
      dealStatuses: new URLSearchParams(search).getAll("dealStatuses")?.length
        ? new URLSearchParams(search).getAll("dealStatuses")
        : DEFAULT_PRECEDENT_FILTERS.dealStatuses,
      clientCompanyIds:
        new URLSearchParams(search).getAll("clientCompanyIds") ?? [],
    };
    parseFilters(filters, false);
    fetchSearchFilters();
  }, []);

  return (
    <div className={"flex flex-col items-start w-full"}>
      <div className={"flex flex-row items-center w-full"}>
        <FiltersModal
          filters={filters}
          onChange={parseFilters}
          currentValues={selectedFilters}
        />

        <Button
          size={"small"}
          hidden={equals(
            omit(["size"], selectedFilters),
            omit(["size"], DEFAULT_PRECEDENT_FILTERS)
          )}
          className={`select-none cursor-pointer mr-2 ${DANGER_BUTTON_STYLE} !font-normal`}
          onClick={(): void => {
            onChange({
              ...DEFAULT_PRECEDENT_FILTERS,
            });
          }}
          icon={<MinusOutlined />}
        >
          Reset Filters
        </Button>
        <FiltersDetails
          selectedFilters={selectedFilters}
          onChange={onChange}
          categorisedFilters={categorisedFilters}
        />
        <span className={"w-full"} />
        <div className={"inline-flex gap-0"}>
          {/* <span>{total && total + "records"}</span> */}
          <TableOutlined className={"bg-gray-200 p-1 rounded-sm"} />
          &nbsp;
          <Segmented
            value={selectedFilters.size ?? 10}
            options={[
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "25", value: 25 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
            ]}
            size={"small"}
            className={"ml-auto mr-2"}
            onChange={(size): void =>
              onChange({ ...selectedFilters, size: size as number })
            }
          />
        </div>
      </div>
      <div className={""}>
        {selectedFilters.listType && (
          <TextTag value={`${selectedFilters.listType}`} />
        )}
        {selectedFilters.listType !== "PORTFOLIO" &&
          selectedFilters.dealStatuses.length > 0 &&
          selectedFilters.dealStatuses.map((item, index) => (
            <StatusTag
              id={"company"}
              key={item}
              name={`+ ${item}`}
              onClose={(): void =>
                onChange({
                  ...selectedFilters,
                  dealStatuses: removeItemFromArray(
                    index,
                    selectedFilters.dealStatuses
                  ),
                })
              }
            />
          ))}
        {selectedFilters.companyIds.slice(0, 5).map((value, index) => (
          <CompanyTag
            key={value}
            name={path([value, "name"], categorisedFilters.company) ?? ""}
            id={value}
            onClose={(): void =>
              onChange({
                ...selectedFilters,
                companyIds: removeItemFromArray(
                  index,
                  selectedFilters.companyIds
                ),
              })
            }
          />
        ))}
        {selectedFilters.companyIds.slice(5).length > 0 && (
          <CompanyTag
            id={"company"}
            name={`+ ${selectedFilters.companyIds.slice(5).length}`}
          />
        )}
        {selectedFilters.fromTimestamp && (
          <DateTag
            onClose={(): void =>
              onChange({ ...selectedFilters, fromTimestamp: null })
            }
            date={selectedFilters.fromTimestamp.toString()}
          />
        )}
        {selectedFilters.toTimestamp && (
          <DateTag
            onClose={(): void =>
              onChange({ ...selectedFilters, toTimestamp: null })
            }
            date={selectedFilters.toTimestamp.toString()}
            startDate={false}
          />
        )}
        {selectedFilters.industryIds.slice(0, 2).map((value, index) => (
          <IndustryTag
            key={value}
            name={path([value, "name"], categorisedFilters.industry) ?? ""}
            id={value}
            onClose={(): void =>
              onChange({
                ...selectedFilters,
                industryIds: removeItemFromArray(
                  index,
                  selectedFilters.industryIds
                ),
              })
            }
          />
        ))}
        {selectedFilters.industryIds.slice(2).length > 0 && (
          <IndustryTag
            id={"industry"}
            name={`+ ${selectedFilters?.industryIds?.slice(2).length}`}
          />
        )}
        {selectedFilters.clientCompanyIds?.slice(0, 5).map((value, index) => (
          <CompanyTag
            key={value}
            name={path([value, "name"], categorisedFilters.company) ?? ""}
            id={value}
            onClose={(): void =>
              onChange({
                ...selectedFilters,
                clientCompanyIds: removeItemFromArray(
                  index,
                  selectedFilters.clientCompanyIds
                ),
              })
            }
          />
        ))}
        {selectedFilters.clientCompanyIds?.slice(5).length > 0 && (
          <CompanyTag
            id={"company"}
            name={`+ ${selectedFilters.clientCompanyIds?.slice(5).length}`}
          />
        )}
        <VersionTag
          key={selectedFilters.versions}
          value={selectedFilters.versions}
        />

        <ResponseTag value={selectedFilters.lenderPublished} />
        {/*{selectedFilters.bidMetricSearchText &&*/}
        {/*  !isEmpty(selectedFilters.bidMetricSearchText) && (*/}
        {/*    <TextTag*/}
        {/*      value={selectedFilters.bidMetricSearchText}*/}
        {/*      onClose={(): void => {*/}
        {/*        onChange({ ...selectedFilters, bidMetricSearchText: "" });*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  )}*/}
      </div>
    </div>
  );
};

type PrecedentSearchFiltersType = {
  selectedFilters: PSFiltersType;
  onChange: (selectedFilters: PSFiltersType) => void;
};

type IndustryTagType = {
  name: string;
  id: string;
  onClose?: () => void;
};

type CompanyTagType = {
  name: string;
  id: string;
  onClose?: () => void;
};

type DateTagType = {
  date: string;
  onClose?: () => void;
  startDate?: boolean;
};

type StatusTagType = {
  name: string;
  onClose?: () => void;
  id: string;
};
type VersionTagType = {
  value: string;
};

type ResponseTagType = {
  value: boolean | null;
};

type TextTagType = {
  value: string;
  onClose?: () => void;
};

export type CategorisedFilterType = {
  industry: Record<string, KeyValueType>;
  company: Record<string, KeyValueType>;
  version: Record<string, KeyValueType>;
};
