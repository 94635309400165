import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  generatePSSheets,
  getSearchExcel,
  getSearchExcelWithFilters,
  getSemanticSearchGlossary,
  getTermsheetDetailsNew,
} from "../../../services/services";
import { Alert, Button, message, Modal, Tag, TreeSelectProps } from "antd";
import { CompareScreenType } from "../../../utils/types";
import { FileResponseType, ResponseType } from "../../../utils/uiTypes";
import { CustomSpin } from "../../general/CustomSpin";
import PrecedentSheets from "./PrecedentSheets";
import { isEmpty } from "ramda";
import { PrecedentToolbar } from "./PrecedentToolbar";
import { getSheetsForTree } from "./utils/sheet-tree-options";
import { prepareSheetsSelection } from "./utils/sheets-selection";
import { PSColWidth, PSFontSize } from "./utils/css";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import { SemanticGlossary } from "./SemanticGlossary";
import { ConfirmDownload } from "../../../utils/confirmationModals";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";

export const PrecedentDetails: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { width: windowWidth, height } = useWindowDimensions();
  const [redline, setRedline] = useState<boolean>(false);
  const [heatmap, setHeatmap] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<PSFontSize>(PSFontSize.NORMAL);
  const [width, setWidth] = useState<PSColWidth>(
    windowWidth < 1024
      ? PSColWidth.NARROW
      : windowWidth > 1780
      ? PSColWidth.WIDE
      : PSColWidth.STANDARD
  );
  const [showMatchedRows, setShowMatchedRows] = useState<boolean>(true);
  const [results, setResults] = useState<CompareScreenType>({
    sheets: [],
    sheetsDataByRow: [],
    matchedTokens: [],
  });
  const [selectedSheets, setSelectedSheets] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [params, setParams] = useState<PSParams>({
    ids: [],
    keywords: [],
    semanticSearch: true,
  });
  const [glossary, setGlossary] = useState<string[]>([]);
  const [showGlossary, setShowGlossary] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const SEMANTIC_SEARCH_MSG = (
    <div className={"flex items-center justify-between w-full"}>
      <p>
        We&apos;ve just made your searches even smarter! Semantic search is now
        active, helping you pin-point the right information within our&nbsp;
        <span
          role={"button"}
          className={"text-primary"}
          onClick={() => setShowGlossary(true)}
        >
          collection
        </span>
        .
      </p>
    </div>
  );

  const psParamsToUrlParams = (params: PSParams): string => {
    const ids = params.ids.map((v) => ["ids", v]);
    const keywords = params.keywords.map((v) => ["keywords", v]);
    const semanticSearch = ["semanticSearch", String(params.semanticSearch)];

    return new URLSearchParams([
      ...ids,
      ...keywords,
      semanticSearch,
    ]).toString();
  };

  const fetchResults: fetchResultsType = (params) => {
    setLoading(true);
    const paramString = psParamsToUrlParams(params);
    history.replace({
      pathname: location.pathname,
      search: paramString,
    });
    getTermsheetDetailsNew({ params: paramString })
      .then(({ data }: ResponseType<CompareScreenType>) => {
        setResults(() => data);
        setLoading(() => false);

        const sheets =
          data?.sheets?.map((item) => ({ ...item, show: true })) ?? [];

        setSelectedSheets(() => sheets.map((item) => item.sheetId));
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };

  const sheetsTree: TreeSelectProps["treeData"] = useMemo(() => {
    return getSheetsForTree(results?.sheets ?? []);
  }, [results?.sheets]);

  const getSheetsSelection = useCallback(
    (selectedIds: string[]) => {
      return prepareSheetsSelection(results.sheets, selectedIds);
    },
    [results.sheets]
  );

  const onUpdateParams = (params: PSParams): void => {
    setParams(params);
    fetchResults(params);
  };

  const parseParams = (urlParams: string): PSParams => {
    const searchParams = new URLSearchParams(urlParams);
    const keywords = searchParams.getAll("keywords") ?? [];
    const ids = searchParams.getAll("ids") ?? [];
    const semanticSearch = Boolean(searchParams.get("semanticSearch")) ?? false;

    return {
      keywords,
      ids,
      semanticSearch,
    };
  };

  const fetchGlossary = (): void => {
    getSemanticSearchGlossary({})
      .then(({ data }: ResponseType<string[]>) => {
        setGlossary(data);
      })
      .catch(console.error);
  };

  const generateCompleteExcel = (params: string): void => {
    message.loading({
      content: "Processing File...",
      duration: 0,
      key: "export",
    });
    getSearchExcel({ params })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "export", content: "File Ready to Download" });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        console.log(error);
        message.error({ content: error, key: "export" });
      });
  };
  const processConfigurations = (inputList: string[]) => {
    const configurations: any = {};

    inputList.forEach((item) => {
      const mainKey = item.split("-")[0];
      if (!configurations[mainKey]) {
        configurations[mainKey] = {
          display: false,
          metric: false,
          ask: false,
        };
      }
      const sheet = results.sheets?.find((item) => item.sheetId === mainKey);
      if (configurations[mainKey].responses === undefined) {
        configurations[mainKey].responses = {};
      }
      if (item === `${mainKey}-BID_METRIC`) {
        configurations[mainKey].metric = true;
        configurations[mainKey].display = true;
      } else if (item === `${mainKey}-ASK`) {
        configurations[mainKey].ask = true;
        configurations[mainKey].display = true;
      } else if (item === `${mainKey}-RESPONSE-ALL`) {
        configurations[mainKey].display = true;
        sheet?.lenderKeys?.map((item) => {
          configurations[mainKey].responses[item] = true;
        });
      } else if (item === `${mainKey}`) {
        configurations[mainKey].display = true;
        configurations[mainKey].ask = true;
        configurations[mainKey].metric = true;
        sheet?.lenderKeys?.map((item) => {
          configurations[mainKey].responses[item] = true;
        });
      } else {
        configurations[mainKey].display = true;
        sheet?.lenderKeys?.map((item) => {
          configurations[mainKey].responses[item] = true;
        });
      }
    });

    return { configurations };
  };
  const downloadPSSheets = () => {
    setDownloading(true);
    const param = {
      ...params,
      redline,
      heatmap,
      showOnlyMatches: params.keywords.length ? showMatchedRows : false,
      semanticSearch: !!params.keywords.length,
      ...processConfigurations(selectedSheets),
      keywords: params.keywords.length ? params.keywords : null,
    };

    generatePSSheets({
      body: JSON.stringify(param),
    })
      .then(({ url, filename }: FileResponseType) => {
        setDownloading(false);
        ConfirmDownload(filename, url);
      })
      .catch((err: string): void => {
        setDownloading(false);
        message.error(err ?? "Something went wrong.", 3);
      });
  };

  useEffect(() => {
    onUpdateParams(parseParams(location.search));
    fetchGlossary();
  }, []);

  return (
    <div
      className={"relative max-h-full w-full h-screen bg-white flex flex-col"}
    >
      <CustomSpin loading={loading} />
      <div className="flex justify-between">
        <div className={"mb-5 flex flex-col justify-between p-6 pb-0 "}>
          <div className={"text-2xl font-medium"}>
            Termsheets Compare <Tag color={"processing"}>Read Only</Tag>
          </div>
          <div>
            Compare your terms across deals and institutions for better analysis
          </div>
        </div>
        <div className="p-8">
          <Button
            loading={downloading}
            className={PRIMARY_BUTTON_STYLE}
            onClick={downloadPSSheets}
          >
            Download
          </Button>
        </div>
      </div>
      <div
        className={`w-full h-full flex flex-col overflow-hidden p-0 relative`}
      >
        <PrecedentToolbar
          heatmap={heatmap}
          setHeatMap={setHeatmap}
          params={params}
          onUpdateParams={onUpdateParams}
          redline={redline}
          setRedline={setRedline}
          showMatchedRows={showMatchedRows}
          setShowMatchedRows={setShowMatchedRows}
          sheetOptions={sheetsTree}
          selectedSheets={selectedSheets}
          setSelectedSheets={setSelectedSheets}
          fontSize={fontSize}
          setFontSize={setFontSize}
          width={width}
          setWidth={setWidth}
          goBack={() => history.goBack()}
          onExport={() => generateCompleteExcel(location.search)}
        />
        {params.semanticSearch && !isEmpty(params.keywords) && (
          <Alert
            className={"py-1.5 text-xs drop-shadow"}
            message={SEMANTIC_SEARCH_MSG}
            type="info"
            showIcon
            banner={true}
          />
        )}
        <PrecedentSheets
          sheets={getSheetsSelection(selectedSheets)}
          rows={results.sheetsDataByRow}
          redline={redline}
          searchMode={showMatchedRows && !isEmpty(params.keywords)}
          heatMap={heatmap}
          fontSize={fontSize}
          width={width}
        />
        <Modal
          title={"Semantic Search Collection"}
          open={showGlossary}
          centered={true}
          onCancel={() => setShowGlossary(false)}
          width={windowWidth / 2}
          bodyStyle={{
            maxHeight: height / 2,
            overflowY: "scroll",
            padding: 0,
          }}
        >
          <SemanticGlossary glossary={glossary} />
        </Modal>
      </div>
    </div>
  );
};

type fetchResultsType = (params: PSParams) => void;
export type PSParams = {
  ids: string[];
  keywords: string[];
  semanticSearch: boolean;
};
