import { DELETE, DOWNLOAD_GET, DOWNLOAD_POST, GET, POST, PUT } from "./request";

const prefix = "/api/rest";

/** Login APIs **/
export const login = POST(prefix + `/users/customLogin`);
export const logout = GET(prefix + `/users/logout`);

/** User APIs **/
export const getUser = GET(prefix + `/users/getUser`);
export const updateUserProfile = POST(prefix + "/users/editProfile");
export const register = POST(prefix + "/users/register");
export const verifyEmail = GET(
  ({ hash }) => `/api/rest/users/verify-email/${hash}`
);
export const forgotPassword = POST(prefix + "/users/forgotPassword");
export const resetPassword = POST(
  ({ hash }) => `${prefix}/users/resetPassword/${hash}`
);
export const joinTeam = POST(
  ({ hash, companyId }) =>
    `${prefix}/users/join-team/${hash}/company/${companyId}`
);
/** Transactions APIs **/
export const getPETransaction = GET(
  ({ id }) => prefix + `/element/getPETransaction/${id}`
);
export const getTransactions = POST(prefix + `/element/getAllPETransactions`);
export const createNewTransaction = POST(prefix + "/element/addPETransaction");
export const editTransaction = POST(prefix + "/element/editPETransaction");
export const deletePETransaction = DELETE(
  ({ id }) => prefix + `/element/deletePETransaction/${id}`
);
export const exportTransaction = DOWNLOAD_GET(
  ({ id }) => prefix + `/generateLenderExcel/${id}`
);
export const getModulePreference = GET(
  ({ id }) => prefix + `/company/getCompanyModulePreference/${id}`
);
export const getModulePreferenceByCompany = GET(
  ({ id }) => prefix + `/company/getCompanyModulePreferenceByCompanyId/${id}`
);
export const updateTag = POST(prefix + `/transaction/status/update`);
export const createTag = POST(prefix + `/transaction/status/create`);
export const updateTransactionDueDate = POST(
  prefix + `/element/updateTransactionDueDate`
);

/** Notifications APIs **/
export const getNotifications = GET(
  ({ limit, offset }) =>
    prefix + `/activity/getActivitesForUser/${limit}/${offset}`
);
export const getDashboardNotifications = GET(
  ({ transactionId, pageIndex, onlyTop5 }) =>
    prefix +
    `/activity/getActivites/${transactionId}/${1000}/${pageIndex}/${onlyTop5}`
);
export const markRead = GET(() => prefix + `/activity/markRead`);
export const markReadForTransaction = GET(
  ({ transactionId }) => prefix + `/activity/markRead/${transactionId}`
);

/** Deadline APIs **/
export const getAllDeadlines = GET(prefix + `/deadline/getAll`);
export const getAllDeadlinesForTransaction = GET(
  ({ transactionId }) =>
    prefix + `/deadline/getAll?transactionId=` + transactionId
);

/** Calendar APIs **/
export const getTransactionsForCalendar = GET(
  prefix + `/element/getAllPETransactionsForCalendar`
);
export const createDeadlineEvent = POST(prefix + `/deadline/create`);
export const updateDeadlineEvent = POST(prefix + `/deadline/update`);
export const deleteDeadlineEvent = POST(prefix + `/deadline/delete`);
export const updatePETransactionColor = POST(
  prefix + `/element/updateTransactionColor`
);

/** Permission APIs **/
export const assignPermission = POST(
  ({ type, id }) =>
    prefix + `/permission/assignPermissionForLenderOrTransaction/${type}/${id}`
);
export const deleteUserDTO = POST(
  ({ id }) => prefix + `/permission/deletePermissionForElement/LENDER/${id}`
);
export const deleteLenderDTO = POST(
  ({ id }) => prefix + `/permission/deleteLender/LENDER/${id}`
);

/** Web Form APIs **/

export const deleteWebForm = DELETE(({ id }) => prefix + `/new-webform/${id}`);
export const deleteWebFormVersion = DELETE(
  ({ id, versionId }) => prefix + `/new-webform/${id}/${versionId}`
);
export const renameWebFormVersion = PUT(
  ({ id, versionId }) => prefix + `/new-webform/${id}/${versionId}`
);
export const renameWebForm = POST(
  ({ id }) => prefix + `/new-webform/rename/${id}`
);
export const getWebFormByVersion = GET(
  ({ id, webFormId, version }) =>
    prefix +
    `/new-webform/getWebFormDataByTransaction/${id}/${webFormId}/${version}`
);

export const getVersionsByTransactionAndWebform = GET(
  ({ transactionId, webFormId }) =>
    prefix + `/new-webform/getVersion/${transactionId}/${webFormId}`
);

export const getAllWebFormsByTransaction = GET(
  ({ id }) => prefix + `/new-webform/getAllWebFormsByTransaction/${id}/`
);
export const markInActiveWebFormConfig = GET(
  ({ id, rowId }) =>
    prefix + `/new-webform/markInActiveWebFormConfig/${id}/${rowId}`
);
export const createNewWebFormConfig = POST(
  ({ id }) => prefix + `/new-webform/createWebFormConfig/${id}`
);
export const getWebformTemplates = GET(
  ({ id, webformId }) =>
    prefix + `/new-webform/getAllTemplates/${id}/${webformId}`
);
export const getWebformBySelectedTemplate = POST(
  ({ id, selectedWebFormId, webformId }) =>
    prefix + `/new-webform/openTemplate/${id}/${selectedWebFormId}/${webformId}`
);

export const cloneVersion = GET(
  ({ id, webFormId, version }) =>
    prefix + `/new-webform/cloneVersion/${id}/${webFormId}/${version}`
);
export const cloneVersionOfflineLender = POST(
  ({ id, webFormId }) =>
    prefix + `/new-webform/cloneVersionOfflineLender/${id}/${webFormId}`
);

export const getWebformConfig = GET(
  ({ id }) => prefix + `/new-webform/getWebFormConfig/${id}`
);
export const getVersions = GET(
  ({ transactionId, webFormId }) =>
    prefix + `/new-webform/getVersion/${transactionId}/${webFormId}`
);

export const updateOrPublishTermSheet = POST(
  prefix + `/new-webform/updateOrPublishTermSheet`
);

export const saveOrPublishWebFormData = POST(
  prefix + `/new-webform/saveOrPublishWebFormData`
);

export const addOrRemindLendersForWebformVersion = POST(
  ({ id, webformId, version }) =>
    prefix + `/new-webform/addOrRemindLender/${id}/${webformId}/${version}`
);
export const addLendersForOfflineTermsheet = POST(
  ({ id, webformId, versionId }) =>
    prefix +
    `/new-webform/addLenderInOfflineTransaction/${id}/${webformId}/${versionId}`
);
export const removeLenderFromWebformVersion = POST(
  ({ id, webformId, version }) =>
    prefix + `/new-webform/unshare/${id}/${webformId}/${version}`
);

export const generateReadlineExcel = DOWNLOAD_POST(
  prefix + `/new-webform/generateReadlineExcel`
);

export const getTermsheetColumns = POST(prefix + `/new-webform/columns`);

export const generateDoc = DOWNLOAD_GET(
  ({ id, webFormId, version }) =>
    prefix + `/new-webform/generateExcel/${id}/${webFormId}/${version}`
);

export const saveOfflineTermsheet = POST(
  prefix + `/new-webform/saveOfflineTermSheetData`
);

export const uploadExcelFile = POST(prefix + `/new-webform/uploadExcelFile`);
export const pastUploadExcelFile = POST(
  prefix + `/new-webform/uploadOfflineTransactionExcelData`
);
export const addNewRowForOfflineTermsheet = POST(
  ({ peTransactionId, webFormId, position }) =>
    prefix +
    `/new-webform/addRowForOfflineTransaction/${peTransactionId}/${webFormId}/${position}`
);

/** Company APIs **/
export const getAllCompanies = GET(
  () => prefix + `/company/getAllCompanyInfo/`
);

export const getCompanyTeam = GET(
  ({ id }) => prefix + `/team/byCompanyId/${id}`
);

export const getTeam = GET(prefix + `/team`);
export const getTeamByCompanyId = GET(
  ({ id }) => prefix + `/team/byCompanyId/${id}`
);

/** Invite APIs **/
export const invite = POST(prefix + `/invite`);
export const resendInvite = POST(prefix + `/invite/resendEmail`);

/** Industry APIs **/
export const getIndustries = GET(prefix + "/industry");

/** Institution APIs **/
export const getInstitutions = GET(prefix + "/company/getAllCompanyInfo/");
export const addInstitution = POST(prefix + "/lenders");
export const createInstitution = POST(prefix + "/company/byLender");
export const getIntitutionsForTransaction = GET(
  ({ transactionId }) => prefix + `/lenders/${transactionId}`
);
export const mergeInstitutions = POST(
  ({ fromCompany, toCompany }) =>
    prefix + `/admin/dashboard/mergeCompany/${fromCompany}/${toCompany}`
);

/** Milestone APIs **/
export const newMilestone = POST(prefix + "/milestones");
export const deleteMilestone = DELETE(
  ({ id, peTransactionId }) => prefix + `/milestones/${id}/${peTransactionId}`
);
export const getMilestones = GET(
  ({ id }) => prefix + `/milestones-templates/${id}`
);
export const updateMilestonesTemplate = PUT(prefix + "/milestones-templates");
export const toggleMilestone = GET(
  ({ lenderId, milestoneId, petransactionId }) =>
    prefix +
    `/markCompleteMileStone/${lenderId}/${milestoneId}/${petransactionId}`
);

/** Deal Team APIs **/
export const getDealTeam = GET(
  ({ id }) =>
    prefix + `/permission/getUsersForPermissionsOnElement/PETRANSACTION/${id}`
);
export const getDealTeamForPortfolioLinkedTransaction = GET(
  ({ id }) =>
    prefix + `/permission/getDealTeamForPortfolioLinkedTransaction/${id}`
);

export const deleteDealTeamMember = POST(
  ({ id }) =>
    prefix + `/permission/deletePermissionForElement/PETRANSACTION/${id}`
);

/** Lender Engagement APIs **/

export const getLenderEngagement = GET(() => prefix + `/lenderEngagement`);

/** Comment APIs **/
export const getUnreadCount = POST(prefix + "/comment/readUnread/count");
export const addComment = POST(prefix + "/comment/addComment");
export const getComments = GET(
  ({ type, id, transactionId }) =>
    prefix + `/comment/getCommentsForElement/${type}/${id}/${transactionId}`
);
export const shareComment = POST(
  ({ id }) => prefix + `/comment/shareComment/${id}`
);
export const readComment = GET(
  ({ transactionId, elementId, type }) =>
    prefix + `/comment/readUnread/${elementId}/${transactionId}/${type}`
);

/** Notes APIs **/
export const getNotes = GET(
  ({ id }) => prefix + `/notes/byTransactionId/${id}`
);
export const getNotesByLenderId = GET(
  ({ id }) => prefix + `/notes/lender/${id}`
);
export const createNote = POST(prefix + "/notes");
export const deleteNote = POST(({ id }) => prefix + `/notes/deleteNote/${id}`);
export const editNote = POST(prefix + "/notes/editNote");
export const setPriorities = PUT(
  ({ type, id }) => prefix + `/${type}/markAsPriorities/${id}`
);
export const shareElement = POST(prefix + "/elementShare");
export const printNotes = DOWNLOAD_POST(
  ({ transactionId }) => prefix + `/notes/printNote/${transactionId}`
);
export const getRecentNotes = GET(prefix + `/notes/getNotesForElement`);

/** Task APIs **/
export const addNewTask = POST(prefix + `/tasks`);
export const updateCurrentTask = PUT(prefix + `/tasks`);
export const changeTaskStatus = PUT(
  ({ status, id }) => prefix + `/tasks/change-status/${status}/${id}`
);
export const deleteCurrentTask = DELETE(
  ({ id }) => prefix + `/tasks/delete/${id}`
);
export const cloneTasks = POST(
  ({ from, to }) => prefix + `/tasks/clone/from/${from}/to/${to}`
);
export const addNewSection = POST(prefix + `/taskSection`);
export const getTasks = GET(
  ({ id }) => prefix + `/taskSection/getAllSectionsByTransaction/${id}`
);
export const exportTasks = DOWNLOAD_GET(
  ({ id }) => prefix + `/taskSection/printTasks/${id}`
);

/** Data Room APIs **/
export const getDataRoom = GET(
  ({ id, type }) => prefix + `/fileelement/${type}/${id}`
);
export const toggleWatermark = PUT(
  ({ id, status }) => prefix + `/media/${id}/watermark/${status}`
);

export const uploadFile = POST(prefix + `/media/uploadFile/1`);

export const addFile = POST(prefix + `/fileelement/addFile`);

export const addFolder = POST(prefix + `/fileelement/addFolderElement`);

export const renameFileOrFolder = POST(prefix + `/fileelement/editFolder`);

export const searchFilesAndFolders = GET(
  ({ id }) => prefix + `/fileelement/${id}`
);

export const deleteFile = DELETE(
  ({ transactionId, fileId }) =>
    prefix + `/fileelement/deleteFileElement/${transactionId}/${fileId}`
);

export const shareFile = POST(
  ({ elementId }) =>
    prefix + `/permission/addAndDeletePermissionForFile/${elementId}`
);

export const downloadFolder = DOWNLOAD_GET(
  ({ elementId, id }) => prefix + `/media/${elementId}/${id}`
);
export const downloadFile = DOWNLOAD_GET(
  ({ fileId }) => prefix + `/media/${fileId}/`
);
export const downloadAccessReport = DOWNLOAD_GET(
  ({ elementId, transactionId }) =>
    prefix + `/fileelement/fileDownloadReport/${elementId}/${transactionId}`
);
export const getLenderDownloadInfo = GET(
  ({ transactionId }) =>
    prefix + `/fileelement/lenderFileDownloadInfo/${transactionId}`
);
export const moveFilesToNewLocation = PUT(prefix + `/fileelement/move`);

/** Company Profile APIs **/
export const getCompanyById = GET(
  ({ companyId }) => prefix + `/company/${companyId}`
);
/** Company Profile APIs **/
export const getCompanyByIdAndHash = GET(
  ({ companyId, hash }) => prefix + `/company/${companyId}/hash/${hash}`
);

export const addCompanyProfile = POST(prefix + "/company");
export const updateCompanyProfile = PUT(prefix + "/company");
export const getGeographies = GET(prefix + "/geography");
export const getCountries = GET(prefix + "/countries");

/** Team **/
export const getAllTeamMembers = GET(prefix + "/team");
export const editTeamMember = POST(prefix + "/team/editTeam");
export const deleteTeamMember = POST(prefix + "/team/deleteUserFromTeam");

/** Templates **/
export const getAllTemplates = GET(prefix + "/company/getTemplatesByCompany");

export const editTemplateById = PUT(
  ({ id }) => prefix + `/new-webform/editTemplate/${id}`
);

export const deleteTemplateById = DELETE(
  ({ companyId, templateId }) =>
    prefix + `/new-webform/deleteTemplate/${companyId}/${templateId}`
);

export const getEmptyTemplate = GET(prefix + `/new-webform/getEmptyTemplate`);

export const createTemplate = POST(prefix + `/new-webform/createTemplate`);

/** Allocation APIs **/
export const getAllocationTable = GET(
  ({ elementId, allocationElementType }) =>
    prefix +
    `/allocationTable/getAllKeysInTable/${elementId}/${allocationElementType}`
);

export const getAllocationsForTable = GET(
  ({ elementId, tableId, allocationElementType }) =>
    prefix +
    `/allocation/getById/${elementId}/tableId/${tableId}/${allocationElementType}`
);
export const updateAllocations = POST(
  ({ elementId, allocationElementType }) =>
    prefix + `/allocation/update/${elementId}/${allocationElementType}`
);
export const updateAllocationDate = POST(
  prefix + `/allocationTable/addAllocationDate/`
);
export const updateAllocationKeyToTable = POST(
  ({ peTransactionId, tablePEMappingId, allocationElementType }) =>
    prefix +
    `/allocationTable/addAndDeleteKeyInTable/${peTransactionId}/tablePEMappingId/${tablePEMappingId}/${allocationElementType}`
);
export const createAllocations = POST(
  ({ elementId, allocationElementType }) =>
    prefix + `/allocation/create/${elementId}/${allocationElementType}`
);
export const deleteLenderFromTable = POST(
  ({ peTransactionIdOrPortfolioId, allocationElementType, tableId }) =>
    prefix +
    `/allocation/deleteRow/${peTransactionIdOrPortfolioId}/${allocationElementType}/${tableId}`
);

/** Allocation Settings **/
export const getAllocationKeys = GET(prefix + `/allocationKey/getKeys`);
export const addAllocationKey = POST(prefix + "/allocationKey/addKey");
export const updateAllocationKey = POST(prefix + "/allocationKey/updateKey");
export const deleteAllocationKey = DELETE(
  ({ id }) => prefix + `/allocationKey/deleteKey/${id}`
);

/** 2FA API **/
export const sendPhoneVerificationCode = POST(
  prefix + "/users/current/2fa-phone/verification-code/send"
);

export const send2FAPhoneCode = POST(prefix + "/2fa/sms/send");

export const verify2FAPhoneCode = POST(
  ({ verifyType }) => prefix + `/2fa/${verifyType}/verify`
);

export const send2FAEmailCode = POST(prefix + "/2fa/email/send");

export const verifyPhoneCode = POST(
  prefix + "/users/current/2fa-phone/verification-code/verify"
);

export const enable2FA = POST(prefix + "/users/current/2fa/sms/enable");

export const disable2FA = POST(prefix + "/users/current/2fa/sms/disable");

// Analytics API
export const getAnalyticsDashboard = GET(prefix + `/analytics/dashboard`);
export const generateAnalyticsData = GET(
  ({ type }) => prefix + `/analytics/reports/${type}`
);
export const downloadAnalyticsData = DOWNLOAD_GET(
  ({ type }) => prefix + `/analytics/reports/${type}`
);

// Notification Settings
const notificationURL = "/users/current/notifications/email/subscriptions";
export const getNotificationSettings = GET(
  prefix + "/users/current/notifications/email/subscriptions"
);
export const notificationSubscribe = POST(
  prefix + notificationURL + "/subscribe"
);
export const notificationUnsubscribe = POST(
  prefix + notificationURL + "/unsubscribe"
);

/** Portfolio Management **/
export const getPortfolios = GET(prefix + `/portfolio`);
export const getPortfolioData = GET(
  ({ portfolioId }) => prefix + `/portfolio/getPortfolioData/${portfolioId}`
);
export const getPortfolioSection = GET(
  ({ portfolioId, sectionId }) =>
    prefix + `/portfolioSection/${portfolioId}/${sectionId}`
);
export const createPortfolio = POST(prefix + "/portfolio/create");
export const deletePortfolioById = DELETE(
  ({ portfolioId }) => prefix + `/portfolio/delete/${portfolioId}`
);
export const createNewPortfolioSection = POST(
  prefix + "/portfolioSection/create"
);
export const deletePortfolioSection = DELETE(
  ({ sectionId }) => prefix + `/portfolioSection/delete/${sectionId}`
);
export const createNewPortfolioSectionField = POST(
  prefix + "/portfolioSectionField/create"
);
export const updatePortfolioSectionField = PUT(
  prefix + `/portfolioSectionField/update`
);
export const removeSectionField = POST(
  prefix + `/portfolioSectionField/delete`
);
export const removePortfolioSection = DELETE(
  ({ sectionID }) => prefix + `/portfolioSection/delete/${sectionID}`
);
export const updatePortfolioRank = POST(
  prefix + "/portfolioSectionField/updateRank"
);
export const getKeyContacts = GET(
  ({ portfolioId }) => prefix + `/portfolioKeyContact/getContact/${portfolioId}`
);
export const createOrUpdateKeyContact = POST(
  prefix + "/portfolioKeyContact/createOrUpdateContact"
);
export const deleteKeyContact = DELETE(
  ({ contactId, portfolioId }) =>
    `${prefix}/portfolioKeyContact/deleteContact/${contactId}/${portfolioId}`
);
export const updatePortfolioSectionFieldAnswer = POST(
  prefix + "/portfolioSectionField/userAnswer"
);
export const getPortfolioTransactionData = GET(
  ({ portfolioId }) =>
    `${prefix}/portfolioWebform/getAllPortfolioTransactionMapping/${portfolioId}`
);
export const getCapitalStructure = GET(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/capital-structure/${sectionId}`
);
export const addCapitalStructureRow = POST(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/capital-structure/${sectionId}/row`
);

export const updateCapitalStructureFields = PUT(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/capital-structure/${sectionId}/fields`
);
export const updateCapitalStructureRow = PUT(
  ({ portfolioId, sectionId, fieldId }) =>
    `${prefix}/portfolio/${portfolioId}/capital-structure/${sectionId}/fields/${fieldId}/value`
);
export const deleteCapitalStructureRow = DELETE(
  ({ portfolioId, sectionId, rank }) =>
    `${prefix}/portfolio/${portfolioId}/capital-structure/${sectionId}/row/${rank}`
);

export const getHedging = GET(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/${sectionId}`
);
export const addHedgingRow = POST(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/hedging-structure/${sectionId}/row`
);

export const updateHedgingFields = PUT(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/hedging-structure/${sectionId}/fields`
);
export const updateHedgingRow = PUT(
  ({ portfolioId, sectionId, fieldId }) =>
    `${prefix}/portfolio/${portfolioId}/hedging-structure/${sectionId}/fields/${fieldId}/value`
);
export const deleteHedgingRow = DELETE(
  ({ portfolioId, sectionId, rank }) =>
    `${prefix}/portfolio/${portfolioId}/hedging-structure/${sectionId}/row/${rank}`
);

export const getAllFacilityKeys = GET(`${prefix}/allocationKey/getKeys`);

export const getCapitalStructureVersion = GET(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolioSection/${portfolioId}/${sectionId}`
);

export const createCapitalStructureVersion = POST(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolioSectionVersion/${portfolioId}/section/${sectionId}/version/addNewTableVersion`
);

export const updateCapitalStructureVersion = PUT(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolioSectionVersion/${portfolioId}/section/${sectionId}/version`
);

export const deleteCapitalStructureVersion = DELETE(
  ({ portfolioId, sectionId, versionId }) =>
    `${prefix}/portfolioSectionVersion/${portfolioId}/section/${sectionId}/version/${versionId}`
);

export const addCovenantRow = POST(
  ({ portfolioId, sectionId, name, type }) =>
    `${prefix}/portfolio/${portfolioId}/covenant/${sectionId}/test-schedule/row/${name}/${type}`
);
export const deleteCovenantRow = DELETE(
  ({ portfolioId, sectionId, rowId }) =>
    `${prefix}/portfolio/${portfolioId}/covenant/${sectionId}/test-schedule/row/${rowId}`
);

export const updateCovenantCellValue = PUT(
  ({ portfolioId, sectionId, fieldId }) =>
    `${prefix}/portfolio/${portfolioId}/covenant/${sectionId}/test-schedule/fields/${fieldId}/value`
);

export const getCovenantTestSchedule = GET(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/covenant/${sectionId}/test-schedule`
);
export const getUnderwritingTerms = GET(
  ({ portfolioId, sectionId, type }) =>
    `${prefix}/portfolio/${portfolioId}/underwriting-terms/${sectionId}/${type}`
);

export const addUnderwritingTermsRow = POST(
  ({ portfolioId, sectionId, type, tableType }) =>
    `${prefix}/portfolio/${portfolioId}/underwriting-terms/${sectionId}/${type}/row/${tableType}`
);

export const deleteUnderwritingTermsRow = DELETE(
  ({ portfolioId, sectionId, type, rowId, tableType }) =>
    `${prefix}/portfolio/${portfolioId}/underwriting-terms/${sectionId}/${type}/row/${tableType}/${rowId}`
);

export const updateUnderwritingTermsRow = PUT(
  ({ portfolioId, sectionId, type, rowId }) =>
    `${prefix}/portfolio/${portfolioId}/underwriting-terms/${sectionId}/${type}/value/${rowId}`
);

export const getAmortization = GET(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}`
);
export const addAmortizationRow = POST(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}/row`
);

export const addAmortizationColumn = POST(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}/fields`
);

export const deleteAmortizationColumn = DELETE(
  ({ portfolioId, sectionId, fieldId }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}/fields/${fieldId}`
);

export const updateAmortizationFields = PUT(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}/fields`
);
export const updateAmortizationRow = PUT(
  ({ portfolioId, sectionId, fieldId }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}/fields/${fieldId}/value`
);
export const deleteAmortizationRow = DELETE(
  ({ portfolioId, sectionId, rank }) =>
    `${prefix}/portfolio/${portfolioId}/amortization/${sectionId}/row/${rank}`
);

export const downloadAmortization = DOWNLOAD_GET(
  `${prefix}/portfolio/download/amortization`
);

export const getAllPortfolioTransactions = GET(
  prefix + "/portfolio/getPetransactionForPortfolio"
);
export const linkTransactionsToPortfolio = POST(
  ({ portfolioId }) => `${prefix}/portfolioWebform/createMapping/${portfolioId}`
);

export const getPortfolioFinancialsHistory = GET(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/history`
);
export const getFinancials = GET(
  ({ portfolioId, sectionId, year }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/${year}`
);

export const saveFinancials = POST(
  ({ portfolioId, sectionId, year }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/${year}/save`
);

export const reorderFinancialFields = PUT(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/reorder`
);

export const addFinancialField = POST(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/fields`
);
export const updateFinancialFields = PUT(
  ({ portfolioId, sectionId }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/fields`
);
export const deleteFinancialField = DELETE(
  ({ portfolioId, sectionId, fieldId }) =>
    `${prefix}/portfolio/${portfolioId}/finance/${sectionId}/fields/${fieldId}`
);

/** Portfolio Documents **/
export const getAllPortfolioTransactionMapping = GET(
  ({ id }) =>
    prefix + `/portfolioWebform/getAllPortfolioTransactionMapping/${id}`
);
export const getPortfolioTermsheets = GET(
  ({ id }) => prefix + `/portfolioWebform/getAllTermSheet/${id}`
);
export const getPortfolioTermsheetData = GET(
  ({ id }) => prefix + `/portfolioWebform/getTermSheetData/${id}`
);
export const addPortfolioTermsheetRow = PUT(
  prefix + `/portfolioWebform/addRow`
);
export const deletePortfolioTermsheetRow = PUT(
  prefix + `/portfolioWebform/deleteRow`
);
export const savePortfolioTermsheet = PUT(
  ({ id }) => prefix + `/portfolioWebform/save/${id}`
);
export const renamePortfolioTermsheet = POST(
  prefix + `/portfolioWebform/rename`
);
export const createNewPortfolioTermsheet = POST(
  prefix + "/portfolioWebform/createWebform/"
);
export const generateKeyTermsExcel = DOWNLOAD_GET(
  ({ webFormId }) =>
    prefix + `/portfolioWebform/generatePortfolioExcel/${webFormId}`
);
export const uploadKeyTermsExcel = PUT(
  prefix + "/portfolioWebform/copyFromExcel"
);
export const getInviteData = GET(({ hash }) => prefix + `/invite/hash/${hash}`);
export const approveOrReject = POST(
  ({ hash }) => prefix + `/invite/admin-approval/${hash}`
);

// Precedent Search
export const getSearchFilters = GET("/api/search/filters");
export const getTermsheetDetails = GET("/api/search/details");
export const getTermsheetDetailsNew = GET("/api/search/detailsForUI");
export const getSemanticSearchGlossary = GET("/api/search/synonyms");
export const getSearchResults = POST("/api/search/results");
export const getSearchExcel = DOWNLOAD_GET("/api/search/generateExcel");
export const getSearchExcelWithFilters = DOWNLOAD_POST(
  "/api/search/filteredDataGenerateExcel"
);

/** ADMIN API **/
export const allEmailUnverified = GET(
  prefix + "/admin/dashboard/allEmailUnverified/"
);

export const getCreateJoinLink = GET(
  prefix + "/admin/dashboard/createJoinLink/"
);

export const getAllCompanyModulePreference = GET(
  prefix + "/admin/dashboard/getAllCompanyModulePreference"
);

export const updateCompanyModule = POST(
  prefix + "/admin/dashboard/addCompanyModulePreference/"
);

export const getAllCompaniesAndTeamMembers = GET(
  prefix + "/admin/dashboard/getAllCompanyAndTeam"
);

export const resetLoginCount = POST(
  prefix + "/admin/dashboard/resetLoginCount"
);

export const updateTeamMemberTransactionRights = POST(
  prefix + "/admin/dashboard/updateTeamMemberTransactionRights"
);

export const setUserPassword = POST(
  ({ emailId }) => prefix + `/admin/dashboard/AdminChangePassword/${emailId}`
);

export const getPlatformActivitiesInit = GET(
  ({ limit, offset }) =>
    prefix + `/admin/dashboard/getActivities/${limit}/${offset}`
);

export const getPlatformActivitiesByTransactionId = GET(
  ({ limit, offset, peTransactionId }) =>
    prefix +
    `/admin/dashboard/getActivities/${limit}/${offset}/${peTransactionId}`
);

export const getTransactionsByCompany = GET(
  ({ companyId }) =>
    prefix + `/admin/dashboard/getTransactionsByCompany/${companyId}`
);

export const getTransactionNames = GET(
  ({ limit, pageNumber }) =>
    prefix + `/admin/dashboard/getTransactions/${limit}/${pageNumber}`
);
export const getTrackersByTransactionId = GET(
  ({ id }) => prefix + `/getMilestonesByTransactionId/${id}`
);

export const cloneTracker = POST(
  ({ transactionId, milestonesTemplateId }) =>
    prefix + `/milestones/clone/${transactionId}/${milestonesTemplateId}`
);
export const searchNoteInTransaction = POST(
  ({ transactionId }) => prefix + `/notes/search/${transactionId}`
);
// get company settings
export const getConfigs = GET(
  ({ companyId, elementType, settingType }) =>
    prefix + `/config/${elementType}/${companyId}/${settingType}`
);

export const getConfig = GET(
  ({ companyId, elementType, configType }) =>
    prefix + `/config/${elementType}/${configType}`
);

// get min password
export const getMinPasswordByHash = GET(
  ({ hash }) => prefix + `/config/getConfig/${hash}`
);

export const updateConfig = PUT(({ id }) => prefix + `/config/update/${id}`);

// update company settings
export const createConfig = POST(prefix + `/config/add`);

export const getUnverifiedEmails = GET(
  prefix + `/admin/dashboard/allEmailUnverified/`
);
export const verifyUser = GET(
  ({ email }) => prefix + `/admin/dashboard/verifyUserEmail/${email}`
);

export const editAdminTeam = POST(
  prefix + "/admin/dashboard/updateUserPermission"
);

export const updateAdmin2FA = POST(
  ({ type, userId }) =>
    prefix + `/admin/dashboard/users/${userId}/2fa/sms/${type}`
);

export const getKeyContactTags = GET(prefix + "/tags/getCompanyTags");

export const getCompanyTags = GET(prefix + "/element/tag");
export const getCompanyTagById = GET(({ id }) => prefix + `/element/tag/${id}`);
export const createCompanyTag = POST(prefix + "/element/tag");
export const updateCompanyTag = PUT(({ id }) => prefix + `/element/tag/${id}`);
export const deleteCompanyTag = DELETE(
  ({ id }) => prefix + `/element/tag/${id}`
);
export const linkTagToElement = PUT(
  ({ tagId, elementType, elementId }) =>
    prefix + `/element/tag/${tagId}/${elementType}/${elementId}`
);
export const unLinkTagFromElement = DELETE(
  ({ elementType, elementId }) =>
    prefix + `/element/tag/${elementType}/${elementId}`
);
export const updateTagRank = PUT(
  ({ tagId, newRank, oldRank }) =>
    prefix + `/element/tag/${tagId}/rank/${oldRank}/${newRank}`
);
export const updateDefaultTag = PUT(
  ({ tagId }) => prefix + `/element/tag/${tagId}/default`
);

export const searchTransactionList = GET(prefix + `/element/searchTransaction`);
export const updatePortfolioName = PUT(prefix + `/portfolio/update`);
export const getUserTransactionsCount = GET(
  prefix + `/element/getUserTransactionsCount`
);

export const getAllocationSummary = GET(
  ({ portfolioId, date, tableType }) =>
    prefix +
    `/portfolioSection/allocationSummary/${portfolioId}/${date}/${tableType}`
);

export const getAllocationTableVersions = GET(prefix + `/allocationTable/all`);
export const editAllocationTableVersion = PUT(
  ({ id }) => prefix + `/allocationTable/${id}`
);
export const deleteAllocationTableVersion = DELETE(
  ({ id }) => prefix + `/allocationTable/${id}`
);

export const copyAllocation = POST(
  ({ portfolioId, sectionId }) =>
    prefix + `/portfolioSection/${portfolioId}/${sectionId}/copy-allocation`
);

// DEAL CLOUD APIs

export const getDealCloudCompanies = GET(
  prefix + `/integration/getDealCloudCompany`
);

export const getDealCloudTransactions = GET(
  prefix + `/integration/getDealCloudTransaction`
);

export const getThirdPartyIntegrations = GET(
  prefix + "/thirdPartyMapping/getAll"
);
export const addThirdPartyIntegrations = POST(
  prefix + "/thirdPartyMapping/add"
);
export const updateThirdPartyIntegrations = POST(
  prefix + "/thirdPartyMapping/update"
);

export const deleteThirdPartyIntegrations = DELETE(
  ({ id }) => prefix + `/thirdPartyMapping/${id}`
);

export const getLenderClickThroughConfigs = GET(
  ({ transactionId }) => prefix + `/nda/getNdaConfig/${transactionId}`
);

export const addLenderClickThroughConfigs = POST(
  ({ transactionId }) => prefix + `/nda/addNdaConfig/${transactionId}`
);

export const addUserClickThroughLog = POST(prefix + `/nda/addUserNdaLog`);

export const checkClickThroughRequired = GET(
  ({ transactionId }) => prefix + `/nda/checkNda/${transactionId}`
);

export const getClickThroughSettings = GET(
  ({ transactionId }) =>
    prefix + `/nda/getTransactionNdaSetting/${transactionId}`
);

export const updateClickThroughSettings = POST(
  prefix + `/nda/updateTransactionNdaSetting`
);

export const deleteCompany = DELETE(
  ({ id }) => prefix + `/admin/dashboard/companies/${id}`
);
export const renameCompany = PUT(
  ({ id }) => prefix + `/admin/dashboard/companies/${id}/name`
);
export const getCompanyInfo = GET(
  ({ id }) => prefix + `/admin/dashboard/companyInfo/${id}`
);

export const addSectionField = POST(
  ({ sectionType }) =>
    prefix +
    `/admin/dashboard/portfolio-management/${sectionType}/section-fields`
);
export const addTableField = POST(
  ({ sectionType }) =>
    prefix + `/admin/dashboard/portfolio-management/${sectionType}/table-fields`
);
export const addAdminPortfolioSection = POST(
  ({ sectionType }) =>
    prefix + `/admin/dashboard/portfolio-management/${sectionType}`
);
export const addPaymentDates = GET(
  () => prefix + `/admin/dashboard/fees/add-payment-dates`
);

export const getNdaLogs = GET(
  ({ transactionId }) => prefix + `/nda/logs/${transactionId}`
);

export const generateNdaLogExcel = DOWNLOAD_GET(
  ({ transactionId }) => prefix + `/nda/generateExcel/${transactionId}`
);

// CRM APIs
// export const mapUserCompanyToInstitution = POST(prefix + "/relationship");
export const getPortfoliosForCrmMappings = GET(
  prefix + "/relationship/portfolios"
);
export const getCrmMappings = GET(prefix + "/relationship");
export const getCrmMappingsExposures = GET(prefix + "/relationship/exposure");
export const deleteCRMMapping = DELETE(
  ({ id }) => prefix + `/relationship/${id}`
);
export const getCrmCompanyDetails = GET(
  ({ id }) => prefix + `/relationship/${id}`
);
export const getCrmCompanyProfile = GET(
  ({ id }) => prefix + `/relationship/profile/${id}`
);
export const updateCrmCompanyProfile = PUT(prefix + `/relationship`);

export const getAllDealActivity = GET(
  ({ id }) => prefix + `/relationship/deals/${id}`
);

export const getAllKeyPersonnel = GET(
  ({ id }) => prefix + `/relationship/personnel/${id}`
);

export const getCrmAllocation = GET(
  ({ id, type }) =>
    prefix + `/relationship/allocations/${id}/allocationTableTypes/${type}`
);

export const getCrmAllocationSummary = GET(
  ({ id }) => prefix + `/relationship/allocations/${id}/allocationSummary`
);

export const addCRMCompanyMapping = POST(prefix + "/thirdPartyMapping/add");
export const updateCRMCompanyMapping = POST(
  prefix + "/thirdPartyMapping/update"
);

export const getCrmAllocationDataAsExcel = DOWNLOAD_GET(
  ({ id, type }) =>
    prefix + `/relationship/allocations/${id}/allocationTableTypes/${type}/csv`
);

export const getCrmNotes = GET(
  ({ id }) => prefix + `/relationship/notes/${id}`
);

export const createInternalNote = POST(
  ({ id }) => prefix + `/relationship/notes/${id}`
);
export const updateInternalNote = PUT(
  ({ id }) => prefix + `/relationship/notes/${id}`
);
export const deleteInternalNote = DELETE(
  ({ crmId, id }) => prefix + `/relationship/notes/${crmId}/${id}`
);
export const changeCompanyType = PUT(
  ({ id, type }) => prefix + `/admin/dashboard/companyCategory/${id}/${type}`
);

export const updateApplicationModuleLevels = PUT(
  prefix + `/admin/dashboard/modulePreference/levels`
);
export const getApplicationModuleLevels = GET(
  prefix + `/admin/dashboard/modulePreference/levels`
);

export const approveOrRejectTeam = PUT(
  ({ companyId, isApproved, email }) =>
    prefix +
    `/admin/dashboard/approveOrRejectUser/${companyId}/${isApproved}/${email}`
);

/** Webhooks Service **/
export const getSubscriptions = GET(prefix + "/webhooks/subscriptions");
export const addSubscription = POST(prefix + "/webhooks/subscriptions");
export const editSubscription = PUT(
  ({ id }) => prefix + `/webhooks/subscriptions/${id}`
);
export const deleteSubscription = DELETE(
  ({ id }) => prefix + `/webhooks/subscriptions/${id}`
);
export const getEventTypes = GET(prefix + "/webhooks/event-types");
export const checkLoginType = GET(prefix + "/users/sso_config");

export const getUserToken = GET(prefix + "/users/token");
export const resetUserToken = GET(prefix + "/users/reset-token");

export const downloadCaptialStructure = DOWNLOAD_GET(
  ({ portfolioId, sectionId, tableVersionId }) =>
    prefix +
    `/portfolio/${portfolioId}/capital-structure/${sectionId}/versionId/${tableVersionId}/download`
);
export const getUserTransactionModulePermissions = GET(({ id }) => {
  return prefix + `/permission/getUserTransactionModulePermissions/${id}`;
});

export const updateUserTransactionModulePermissions = PUT(({ id }) => {
  return prefix + `/permission/updateUserTransactionModulePermissions/${id}`;
});
export const updateTransactionNotifications = PUT(
  ({ peTransactionId, status }) =>
    prefix + `/element/transactionNotification/${peTransactionId}`
);

export const getTransactionNotifications = GET(
  ({ peTransactionId }) =>
    prefix + `/element/transactionNotification/${peTransactionId}`
);
export const getCompanyTeamById = GET(
  ({ id }) => prefix + `/team/company/${id}`
);
export const getAllWip = POST(prefix + `/transaction/wip/getAll`);
export const getWip = GET(
  ({ peTransactionId }) => prefix + `/transaction/wip/get/${peTransactionId}`
);
export const updateWIP = PUT(
  ({ peTransactionId }) => prefix + `/transaction/wip/update/${peTransactionId}`
);

export const getOrCreateKPI = POST(
  ({ peTransactionId }) =>
    prefix + `/transaction/dealkpi/getOrCreate/${peTransactionId}`
);
export const updateKPI = PUT(prefix + `/transaction/dealkpi/update`);

export const getDebtStructure = GET(prefix + `/debtStructure`);

export const getDebtType = GET(prefix + `/debtType`);

export const checkPortfolioIntersections = GET(
  ({ fromCompany, toCompany }) =>
    prefix +
    `/admin/dashboard/checkPortfolioIntersections/${fromCompany}/${toCompany}`
);
export const downloadNotesAsExcel = DOWNLOAD_POST(
  ({ transactionId }) => prefix + `/notes/downloadNote/${transactionId}`
);

// Leakage Analysis
export const getLeakageTemplates = GET(prefix + "/leakage-data/template");
export const addLeakageTemplate = POST(prefix + "/leakage-data/template");
export const updateLeakageTemplate = PUT(
  ({ templateId }) => prefix + `/leakage-data/template/${templateId}`
);
export const deleteLeakageTemplate = DELETE(
  ({ templateId }) => prefix + `/leakage-data/template/${templateId}`
);
export const getLeakageTemplate = GET(
  ({ templateId }) => prefix + `/leakage-data/template/${templateId}`
);

export const addLeakageData = POST(
  ({ dealId }) => prefix + `/leakage-data/${dealId}`
);
export const getLeakageTemplateCompany = GET(
  ({ showHidden }) => prefix + `/leakage-template?showHidden=${showHidden}`
);

export const updateLeakageTemplateCompany = PUT(
  ({ templateId }) => prefix + `/leakage-template/${templateId}`
);

export const getLeakageData = GET(
  ({ elementId, elementType }) =>
    prefix + `/leakage-data/${elementType}/${elementId}`
);

export const updateLeakageData = PUT(
  ({ id }) => prefix + `/leakage-data/key/${id}/`
);

export const updateLeakageTemplateRow = PUT(
  ({ id }) => prefix + `/leakage-template/${id}/`
);

export const updateLeakageBulkdata = PUT(() => prefix + `/leakage-data/`);

export const updateLeakageTemplateBykey = PUT(
  ({ templateId, keyId }) =>
    prefix + `/leakage-template/${templateId}/updateKey/${keyId}`
);
export const downloadHedgingStructure = DOWNLOAD_GET(
  ({ portfolioId, sectionId }) =>
    prefix + `/portfolio/${portfolioId}/hedging-structure/${sectionId}/download`
);
export const downloadTestScheduled = DOWNLOAD_GET(
  ({ portfolioId, sectionId }) =>
    prefix +
    `/portfolio/${portfolioId}/covenant/${sectionId}/test-schedule/download`
);
export const downloadAllocationFeesStructure = DOWNLOAD_GET(
  ({ portfolioId, tableId, type }) =>
    prefix +
    `/allocation/PORTFOLIO/${portfolioId}/table/${tableId}/${type}/download`
);
export const downloadAllocationSummary = DOWNLOAD_GET(
  ({ portfolioId, fxRateDate, currency, type }) =>
    prefix +
    `/portfolioSection/allocationSummary/${portfolioId}/${fxRateDate}/${currency}/${type}/download`
);

export const getSmartActions = GET(() => prefix + `/element/tag/smartActions/`);

export const getExternalContacts = GET(
  ({ id }) => prefix + `/relationship/contacts/${id}`
);

export const mapInstitutionstoRelationship = POST(
  () => prefix + `/relationship/addInstitutions/`
);
export const addContacts = POST(() => prefix + `/relationship/contacts/`);

export const getDealsForNotes = GET(() => prefix + `/relationship/deals/all`);

export const getPortfoliosForNotes = GET(() => prefix + `/portfolio`);

export const downloadNote = DOWNLOAD_GET(
  ({ id }) => prefix + `/media/notes/${id}`
);

export const generatePSSheets = DOWNLOAD_POST(
  () => `/api/search/generateExcel`
);
