import React, { FC, useContext, useState } from "react";
import { PSFiltersType } from "./PrecedentSearch";
import { Button, Collapse, Modal, Space } from "antd";
import {
  CalendarOutlined,
  ExpandOutlined,
  UnorderedListOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { isEmpty, path } from "ramda";
import { removeItemFromArray } from "../../utils/utils";
import {
  CategorisedFilterType,
  CompanyTag,
  IndustryTag,
} from "./PrecedentSearchFilters";
import { convertDateToFormat } from "../../utils/moment";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { mapResponsesReverse } from "../../utils/precedent";
import { CompanyEnumType } from "../../utils/enums";
import { UserContext } from "../../context/UserContext";

export const FiltersDetails: FC<FilterDetailsType> = ({
  selectedFilters,
  onChange,
  categorisedFilters,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  return (
    <>
      <Button
        size={"small"}
        className={`select-none cursor-pointer mr-2 ${PRIMARY_BUTTON_STYLE} !font-normal`}
        onClick={(): void => setIsOpen(true)}
        icon={<ExpandOutlined />}
      >
        Expand Filters
      </Button>
      <Modal
        title={"Current Filters"}
        footer={false}
        open={isOpen}
        onCancel={(): void => setIsOpen(false)}
        width={"800px"}
        bodyStyle={{ padding: 0 }}
      >
        <Space direction={"vertical"} className={"p-6 bg-gray-100 w-full"}>
          <div
            className={
              "flex flex-row items-center justify-between p-2 px-4 shadow bg-white"
            }
          >
            <div className={"inline-flex items-center gap-2"}>
              <UnorderedListOutlined /> View
            </div>
            <div className={"text-gray-400"}>
              <div className={"text-gray-400"}>
                {selectedFilters?.listType
                  ? selectedFilters?.listType
                  : "Not Selected"}
              </div>
            </div>
          </div>
          <div
            className={
              "flex flex-row items-center justify-between p-2 px-4 shadow bg-white"
            }
          >
            <div className={"inline-flex items-center gap-2"}>
              <i className="fa-solid fa-check-double" /> Response Status
            </div>
            <div className={"text-gray-400"}>
              <div className={"text-gray-400"}>
                {mapResponsesReverse(selectedFilters?.lenderPublished)}
              </div>
            </div>
          </div>
          <div
            className={
              "flex flex-row items-center justify-between p-2 px-4 shadow bg-white"
            }
          >
            <div className={"inline-flex items-center gap-2"}>
              <i className="fa-solid fa-code-compare" /> Termsheet Version
            </div>
            <div className={"text-gray-400"}>
              <div className={"text-gray-400"}>{selectedFilters?.versions}</div>
            </div>
          </div>
          <div
            className={
              "flex flex-row items-center justify-between p-2 px-4 shadow bg-white"
            }
          >
            <div className={"inline-flex items-center gap-2"}>
              <CalendarOutlined /> Date
            </div>
            <div className={"text-gray-400"}>
              {selectedFilters?.fromTimestamp
                ? convertDateToFormat(
                    selectedFilters?.fromTimestamp?.toString(),
                    "D.MMM.YY"
                  )
                : "Not Selected"}
            </div>
          </div>
          <div
            className={
              "flex flex-row items-center justify-between p-2 px-4 shadow bg-white"
            }
          >
            <div className={"inline-flex items-center gap-2"}>
              <CalendarOutlined /> End Date
            </div>
            <div className={"text-gray-400"}>
              {selectedFilters?.toTimestamp
                ? convertDateToFormat(
                    selectedFilters?.toTimestamp?.toString(),
                    "D.MMM.YY"
                  )
                : "Not Selected"}
            </div>
          </div>

          <Collapse
            className={"w-full shadow select-none"}
            bordered={false}
            defaultActiveKey={["1"]}
          ></Collapse>
          <Collapse
            className={"w-full shadow select-none"}
            bordered={false}
            defaultActiveKey={["1"]}
          >
            <Collapse.Panel
              header={
                <div className={"flex gap-2 items-center"}>
                  <ShopOutlined /> Industries
                </div>
              }
              key="1"
            >
              {selectedFilters.industryIds.map((value, index) => (
                <IndustryTag
                  key={value}
                  name={
                    path([value, "name"], categorisedFilters.industry) ?? ""
                  }
                  id={value}
                  onClose={(): void =>
                    onChange({
                      ...selectedFilters,
                      industryIds: removeItemFromArray(
                        index,
                        selectedFilters.industryIds
                      ),
                    })
                  }
                />
              ))}
              {isEmpty(selectedFilters.industryIds) && (
                <div className={"text-gray-400"}>No Industries Selected</div>
              )}
            </Collapse.Panel>
          </Collapse>
          <Collapse
            className={"w-full shadow select-none"}
            bordered={false}
            defaultActiveKey={["1"]}
          >
            <Collapse.Panel
              header={
                <div className={"flex gap-2 items-center"}>
                  <UserOutlined /> Institutions
                </div>
              }
              key="1"
            >
              {selectedFilters.companyIds.map((value, index) => (
                <CompanyTag
                  key={value}
                  name={path([value, "name"], categorisedFilters.company) ?? ""}
                  id={value}
                  onClose={(): void =>
                    onChange({
                      ...selectedFilters,
                      companyIds: removeItemFromArray(
                        index,
                        selectedFilters.companyIds
                      ),
                    })
                  }
                />
              ))}
              {isEmpty(selectedFilters.companyIds) && (
                <div className={"text-gray-400"}>No Institutions Selected</div>
              )}
            </Collapse.Panel>
          </Collapse>
          {selectedFilters?.listType !== "PORTFOLIO" && (
            <Collapse
              className={"w-full shadow select-none"}
              bordered={false}
              defaultActiveKey={["1"]}
            >
              <Collapse.Panel
                header={
                  <div className={"flex gap-2 items-center"}>
                    <i className="fa-solid fa-check-double" /> Deal Status
                  </div>
                }
                key="1"
              >
                {selectedFilters?.dealStatuses?.map((value, index) => (
                  <CompanyTag
                    key={value}
                    name={value}
                    id={value}
                    onClose={(): void =>
                      onChange({
                        ...selectedFilters,
                        dealStatuses: removeItemFromArray(
                          index,
                          selectedFilters.dealStatuses
                        ),
                      })
                    }
                  />
                ))}
                {isEmpty(selectedFilters.dealStatuses) && (
                  <div className={"text-gray-400"}>No status Selected</div>
                )}
              </Collapse.Panel>
            </Collapse>
          )}

          {user?.companyDTO?.companyType ===
            CompanyEnumType.DEBT_ADVISOR_COMPANY ||
          user?.companyDTO?.companyType === CompanyEnumType.LAW_FIRM ? (
            <Collapse
              className={"w-full shadow select-none"}
              bordered={false}
              defaultActiveKey={["1"]}
            >
              <Collapse.Panel
                header={
                  <div className={"flex gap-2 items-center"}>
                    <UserOutlined /> Client Company
                  </div>
                }
                key="1"
              >
                {selectedFilters.clientCompanyIds.map((value, index) => (
                  <CompanyTag
                    key={value}
                    name={
                      path([value, "name"], categorisedFilters.company) ?? ""
                    }
                    id={value}
                    onClose={(): void =>
                      onChange({
                        ...selectedFilters,
                        clientCompanyIds: removeItemFromArray(
                          index,
                          selectedFilters.clientCompanyIds
                        ),
                      })
                    }
                  />
                ))}
                {isEmpty(selectedFilters.clientCompanyIds) && (
                  <div className={"text-gray-400"}>
                    No Institutions Selected
                  </div>
                )}
              </Collapse.Panel>
            </Collapse>
          ) : (
            ""
          )}
        </Space>
      </Modal>
    </>
  );
};

type FilterDetailsType = {
  selectedFilters: PSFiltersType;
  onChange: (selectedFilters: PSFiltersType) => void;
  categorisedFilters: CategorisedFilterType;
};
