import { PSFiltersType } from "./PrecedentSearch";
import React, { FC, useContext, useState } from "react";
import { FiltersResponseType } from "../../utils/types";
import { Button, DatePicker, Form, Modal, Segmented } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { MultipleSelect } from "../general/MultipleSelect";
import moment from "moment";
import {
  CalendarOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { mapResponses, mapResponsesReverse } from "../../utils/precedent";
import { useLocation } from "react-router";
import { UserContext } from "../../context/UserContext";
import { CompanyEnumType } from "../../utils/enums";

export const FiltersModal: FC<FiltersModalType> = ({
  filters,
  onChange,
  currentValues,
}) => {
  const { search } = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [responsesDisabled, setResponsesDisabled] = useState<boolean>(false);
  const [listType, setListType] = useState<string>("ALL");
  const { user } = useContext(UserContext);

  const onVersionChange = (val: string | number): void => {
    val === "ALL" ? setResponsesDisabled(false) : setResponsesDisabled(true);
  };

  const onSubmit = ({
    timeStamp,
    ...values
  }: PSFiltersType & { timeStamp: any[] }): void => {
    onChange({
      ...values,
      fromTimestamp: timeStamp?.[0] ? moment(timeStamp[0]).valueOf() : null,
      toTimestamp: timeStamp?.[1] ? moment(timeStamp[1]).valueOf() : null,
      lenderPublished: mapResponses(
        values.lenderPublished as unknown as string | number
      ),
    });
    setIsOpen(false);
  };

  return (
    <>
      <Button
        size={"small"}
        className={`select-none cursor-pointer mr-2 ${PRIMARY_BUTTON_STYLE} !font-normal`}
        onClick={(): void => setIsOpen(true)}
        icon={<PlusOutlined />}
      >
        Set Filters
      </Button>
      <Modal
        width={"600px"}
        open={isOpen}
        destroyOnClose={true}
        title={"Precedent Search"}
        footer={false}
        bodyStyle={{ padding: 0 }}
        onCancel={(): void => setIsOpen(false)}
      >
        <Form
          initialValues={{
            ...currentValues,
            timeStamp: [
              new URLSearchParams(search).get("fromTimestamp")
                ? moment(
                    Number(new URLSearchParams(search).get("fromTimestamp"))
                  )
                : null,
              new URLSearchParams(search).get("toTimestamp")
                ? moment(Number(new URLSearchParams(search).get("toTimestamp")))
                : null,
            ],
            lenderPublished: mapResponsesReverse(currentValues.lenderPublished),
            versions: new URLSearchParams(search).get("versions") || "LAST",
          }}
          onFinish={onSubmit}
          labelAlign={"right"}
          labelCol={{ flex: "150px" }}
          labelWrap={true}
          wrapperCol={{ flex: 1 }}
          colon={false}
        >
          <div className={"px-6 pt-5"}>
            <Form.Item
              name={"listType"}
              label={
                <div>
                  <UnorderedListOutlined /> View
                </div>
              }
            >
              <Segmented
                value={listType}
                onChange={(e) => {
                  setListType(e.toString());
                }}
                options={[
                  { label: "ALL", value: "ALL" },
                  { label: "TRANSACTION", value: "TRANSACTION" },

                  { label: "PORTFOLIO", value: "PORTFOLIO" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={"lenderPublished"}
              label={
                <div>
                  <i className="fa-solid fa-check-double" /> Response Status
                </div>
              }
            >
              <Segmented
                disabled={listType === "PORTFOLIO"}
                options={[
                  { label: "ANY", value: "ANY" },
                  { label: "RESPONDED", value: "RESPONDED" },
                  { label: "NOT RESPONDED", value: "NOT RESPONDED" },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="timeStamp"
              label={
                <div>
                  <CalendarOutlined />
                  Date
                </div>
              }
            >
              <DatePicker.RangePicker
                ranges={{
                  "Last 3 months": [moment().add(-3, "month"), moment()],
                  "Last 6 months": [moment().add(-6, "month"), moment()],
                  "Last 12 months": [moment().add(-1, "year"), moment()],
                }}
                format={"DD MMMM YYYY"}
              />
            </Form.Item>
            <Form.Item
              name={"versions"}
              label={
                <div>
                  <i className="fa-solid fa-code-compare" /> Termsheet Version
                </div>
              }
            >
              <Segmented
                onChange={onVersionChange}
                disabled={listType === "PORTFOLIO"}
                options={filters.versionFilters?.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Form.Item>
            <Form.Item
              name={"industryIds"}
              label={
                <div>
                  <ShopOutlined /> Industries
                </div>
              }
            >
              <MultipleSelect
                label={"Industries"}
                placeholder={"Select Industries"}
                options={filters.industries.map(({ id, name }) => ({
                  label: name,
                  value: id,
                  filterValue: name,
                }))}
              />
            </Form.Item>
            <Form.Item
              name={"companyIds"}
              label={
                <div>
                  <UserOutlined /> Institutions
                </div>
              }
            >
              <MultipleSelect
                label={"Institutions"}
                placeholder={"Select Institutions"}
                options={filters.companies.map(({ id, name }) => ({
                  label: name,
                  value: id,
                  filterValue: name,
                }))}
              />
            </Form.Item>
            {user?.companyDTO?.companyType ===
              CompanyEnumType.DEBT_ADVISOR_COMPANY ||
            user?.companyDTO?.companyType === CompanyEnumType.LAW_FIRM ? (
              <Form.Item
                name={"clientCompanyIds"}
                label={
                  <div>
                    <UserOutlined /> Client Company
                  </div>
                }
              >
                <MultipleSelect
                  label={"Client Company"}
                  placeholder={"Select Client Company"}
                  options={filters.companies.map(({ id, name }) => ({
                    label: name,
                    value: id,
                    filterValue: name,
                  }))}
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              hidden={listType === "PORTFOLIO"}
              name={"dealStatuses"}
              label={
                <div>
                  <i className="fa-solid fa-check-double" /> Deal Status
                </div>
              }
            >
              <MultipleSelect
                label={"Status"}
                placeholder={"Select Deal Status"}
                options={filters.statuses?.map((name) => ({
                  label: name.replaceAll("_", " "),

                  value: name,

                  filterValue: name,
                }))}
              />
            </Form.Item>
          </div>
          <div
            className={
              "border border-t flex flex-row items-center justify-end gap-2 px-6 py-3"
            }
          >
            <Button className={PRIMARY_BUTTON_STYLE} htmlType={"submit"}>
              Update Filter
            </Button>
            <Button onClick={(): void => setIsOpen(false)}>Cancel</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

type FiltersModalType = {
  currentValues: PSFiltersType;
  filters: FiltersResponseType;
  onChange: (filters: PSFiltersType) => void;
};
