import {
  AccessLevelType,
  AllocationElementType,
  AllocationTableType,
  AllocationTableVersionType,
  AnalyticsChartType,
  AnalyticsCurrencyType,
  AttributeType,
  CompanyEnumType,
  ConfigType,
  ConfigurationSettingType,
  CurrencyType,
  ElementType,
  FileElementEnumType,
  InvitationStatusType,
  InvitationType,
  LeakageDataTypes,
  LeakageTemplateType,
  ModuleType,
  MonetaryType,
  NotificationActivityEnumType,
  NotificationSettingType,
  PermissionType,
  PETransactionCreationType,
  PETransactionCreatorType,
  PETransactionTagType,
  PortfolioSectionEnumType,
  QuarterType,
  RelationshipInstitutionType,
  RelationshipInvestmentType,
  RelationshipStructureType,
  SectionFieldType,
  SmartTagActionType,
  SortDirection,
  ThirdPartyIntegrationType,
  UnitType,
} from "./enums";
import { TableDataType } from "./allocation";
import { externalAttendeesType } from "../components/relationship/Notes";
import { UploadFile } from "antd";

/** User Types **/
export type UserType = {
  authToken?: string;
  companyId?: string;
  createTransaction?: boolean;
  createOfflineTransaction?: boolean;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isAdmin: boolean;
  admin?: boolean;
  lastLogin: string;
  userId: string;
  title: string;
  phoneNumber: string;
  companyDTO?: CompanyType;
  elementPermissions: Array<ElementPermissionsType>;
  hasTeam: boolean;
  authorities: Array<string>;
  invitationStatus?: InvitationStatusType;
  sms2FaEnabled: boolean;
  sms2FaPhoneNumber: string;
  sms2FaPhoneVerified: boolean;
  sms2FaFlowCompleted?: boolean;
  failedLoginCount?: number;
  isSsoMember?: boolean;
};

/** Team Type **/
export type TeamType = {
  id: string;
  userDTO: UserType;
};
/** Transaction Types **/
export type TransactionType = {
  companyId: string;
  dealTeamCompanyId: string;
  color: string;
  companyToBuy: string;
  createDate: string;
  companyName: string;
  lastActivityDate: string;
  createdBy: string;
  createdByUserDTO: UserType;
  closingDate: string;
  deleted: boolean;
  description: string;
  lenderDTOs: Array<LenderType>;
  industryDTOSet: Array<IndustryType>;
  milestonesTemplateId: string;
  modifiedDate: string;
  peTransactionId: string;
  peTransactionNotificationCount: string;
  peTransactionTitle: string;
  permissionDTO: PermissionDataType;
  targetDate: string;
  teamDTO: ElementObjectType;
  watermark: boolean;
  peTransactionTypeId: string;
  peTransactionTypeName: string;
  peTransactionTagType: PETransactionTagType;
  investmentTypeId: string;
  investmentTypeName: string;
  ndaConfigId: string;
  ndaRequired: boolean;

  // CHECK USAGE
  offline: boolean;

  peTransactionCreatorType: PETransactionCreatorType;
  peTransactionCreationType: PETransactionCreationType;
  creationDate: string;
  thirdPartyIntegrationType: ThirdPartyIntegrationType;
  thirdPartyId: string;
  portfolioId?: string;
  creatorCompanyName?: string;
  creatorCompanyId?: string;
};

export type PeTransactionFilter = {
  size: number;
  pageIndex: number;
  searchKey: string;
  direction: SortDirection;
  sortColumn: string;
  status?: string[];
  createdByMe?: boolean;
  sponsor?: string[];
  debtType?: string[];
  transactionType?: string[];
  investmentType?: string[];
  industries?: string[];
  lenders?: string[];
  activeLendersOnly?: boolean;
  fromTimestamp?: number | null;
  toTimestamp?: number | null;
  region?: string[];
  createdBy?: string[];
};

type PaginatedResult<T> = {
  results: T[];
  totalCount: number;
};

export type PeTransactionResponseType = PaginatedResult<TransactionType>;

export type NotificationDataMapType = {
  COMPANY: string;
  CREATOR_EMAIL: string;
  CREATOR_ID: string;
  CREATOR_NAME: string;
  DETAILS: string;
  ELEMENT_TYPE: string;
  LENDER_TITLE: string;
  PROJECT_NAME: string;
  SECTION_NAME: string;
  USER_NAME: string;
  TITLE: string;
  PROPERTY_ID: string;
};
export type NotificationActivityType = {
  activityType: NotificationActivityEnumType;
  createDate: string;
  dataMap: NotificationDataMapType;
  elementId: string;
  elementType: string;
  id: string;
  message: string;
  petransactionId: string;
  transactionName: string;
};
export type NotificationType = {
  count: string;
  activityDTOs: Array<NotificationActivityType>;
};
export type DashboardNotificationType = Record<
  NotificationActivityEnumType,
  NotificationType
>;
export type DeadLineType = {
  id: string;
  peTransactionId: string;
  transactionName: string;
  lenderDTOS: Array<LenderType>;
  targetDate: string;
  title: string;
  color: string;
  active: boolean;
};

export type CalendarTransactionType = {
  color: string;
  deleted: boolean;
  lenderDTOs: [
    {
      id: string;
      name: string;
      webFormIndex: string;
      active: boolean;
    }
  ];
  peTransactionId: string;
  peTransactionNotificationCount: string;
  peTransactionTitle: string;
  permissionDTO: {
    canReAssign: Array<string>;
    elementId: string;
    permissions: Array<string>;
  };
};

export type ElementObjectType = {
  elementId: string;
  elementType: string;
};

/** Tracker Types **/
export type MileStoneType = {
  mileStoneId: string;
  mileStoneTemplateId: string;
  petransactionId?: string | null;
  rank: string;
  name: string;
  key: string;
  done: boolean;
};

/** Permission Types **/
export type ElementPermissionsType = {
  elementType: ElementType;
  permissions: Array<PermissionType>;
};
export type PermissionDataType = {
  userId: string;
  elementType: string;
  elementId: string;
  permissions: Array<PermissionType>;
  canReAssign: Array<PermissionType>;
  implicit: boolean;
};

/** Lender and Company Types **/
export type LenderType = {
  id: string;
  active: boolean;
  companyDTO: CompanyType;
  milestoneDTOs: Array<MileStoneType>;
  name: string;
  lenderName: string;
  ndaConfigId: string;
  ndaText: string;
  ndaRequired: boolean;
  webFormIndex: string;
  userDTOs: Array<UserType>;
  tagDTOs: TagType[];
};

/** Termsheet Types **/
export type SaveOrPublishTermsheetType = {
  peTransactionId: string;
  webFormDTO?: WebFormType;
  webFormDataDTO?: WebFormDataType;
  publishingLenderIds?: Array<string> | null;
  webFormId?: string;
  version: string;
  lenderPublish?: boolean | null;
};

export type TemplateResponseType = {
  webFormId: string;
  templateId: string;
  templateName: string;
  createDate: string;
  createdByUserDTO: UserType;
};

export type WebFormTemplateType = {
  id: string;
  name: string;
  companyId: string;
  data: string;
};

export type WebFormType = {
  id: string;
  name: string;
  peTransactionId: string;
  createDate: string;
  sponsorPublishedDate?: string;
  currentVersion: string;
  createdByUserDTO?: UserType;
  versionData: VersionDataType;
  webFormConfigDTOs?: Array<WebFormConfigType>;
};

export type WebFormDataType = {
  data: DataMapType;
  versionId: string;
  published?: boolean;
  lastPublishedDate?: string;
};

export type WebFormConfigType = {
  id: string;
  position: string;
  uiKey: UiDataType;
  dataMap: DataMapType;
};
export type UiDataType = {
  style: KeyValueType;
  text: string;
  unit: string;
  comment?: Array<string>;
};

export type VersionDataType = { [k: string]: VersionType };
export type DataMapType = { [k: string]: UiDataType };

export type WebFormRedLineType = {
  bidMetricColumn: any;
  originalColumn: any;
  changedColumn: Array<any>;
};

export type VersionType = {
  name: string;
  createDate: string;
  isArchive: boolean;
  published: boolean;
  numberOfRespondants: string;
  lenderIds: Array<string>;
  respondStatus: { [k: string]: boolean };
  sponsorPublishDate?: Record<string, string>;
};
export type ColumnType = {
  webFormId: string;
  peTransactionId: string;
  entityId?: string;
  version?: string;
  colName?: string;
  createDate?: string;
};

/** General Types **/
export type KeyValueType = { [k: string]: string };

export type IndustryType = {
  id: string;
  name: string;
  active: boolean;
  approved: boolean;
};

export type CommentType = {
  comment: string;
  commentDTOs: CommentType[];
  commentId: string;
  commentType: string;
  commentCount: string;
  createDate: string;
  createdBy: string;
  createdByUserDTO: UserType;
  elementId: string;
  elementType: ElementType.PETRANSACTION | ElementType.LENDER;
  priorities: boolean;
  read?: boolean;
  rootCommentId?: string;
};

export type LenderEngagementDataType = {
  id: string;
  userName: string;
  userCompany: string;
  txnName: string;
  lastActionDate: string;
  actionCount: string;
  elementType: ElementType;
  module: ModuleType;
  action: string;
  elementId?: string;
  elementName?: string;
};

export type NotesType = {
  count: string;
  lenderDTO: LenderType;
  notes: NoteDataType[];
};
export type notesExtensionType = {
  interactionDate: string;
  interactionAttendeesExternal: externalAttendeesType[];
  interactionAttendeesInternal: UserType[];
  interactionType: string;
  purpose: "DEAL" | "PORTFOLIO" | "OTHER";
  dealName: string;
  externalUsers: string;
};
export type NoteDataType = {
  lenderName: string;
  createDate: string;
  createdByUserDTO: UserType;
  elementId: string;
  elementType: ElementType;
  elementName: string;
  id: string;
  lenderId: string;
  modifiedDate: string;
  note: string;
  priorities: boolean;
  sharedWith: UserType[];
  portfolio: string;
  fileElements: UploadFile[] | undefined;
  notesExtension: notesExtensionType;
} & notesExtensionType;

export type GeographyType = {
  id: string;
  name: string;
  active: boolean;
};

export type InstitutionType = {
  createdBy: string;
  id: string;
  name: string;
  payment: boolean;
  tncApproved: boolean;
  industries: IndustryType[];
  geographies: GeographyType[];
};

export type TaskType = {
  id: string;
  sectionHeading: string;
  taskDTOs: TaskDTOType[];
  transactionId: string;
};

export type TaskDTOType = {
  active: boolean;
  createDate: string;
  createdBy: string;
  createdByUserDTO: UserType;
  dueDate: string;
  id: string;
  modifiedDate: string;
  taskAssigneesDTO: taskAssigneesDTO[];
  taskSectionId: string;
  taskStatus: string;
  taskTitle: string;
  transactionId: string;
};

export type taskAssigneesDTO = {
  active: boolean;
  assignToId: string;
  assignedBy: string;
  completed: boolean;
  createdBy: string;
  id: string;
  userDTO: UserType;
};

export type AllocationTableMappingType = {
  allocationDate: string;
  allocationTableType: AllocationTableType;
  tableVersionType: AllocationTableVersionType;
  allocationKeyTableMappingDTOS: Array<AllocationKeyTableMappingType>;
  elementId: string;
  id: string;
  name: string;
};

export type AllocationKeyTableMappingType = {
  id: string;
  allocationKeyDTO: AllocationKeyType;
};

export type AllocationKeyType = {
  allocationTableType: AllocationTableType;
  canDelete: boolean;
  companyId: string;
  fieldType: string;
  id: string;
  keyName: string;
  userName: string;
  externalId: string;
};

export type AllocationType = {
  allocationKeyDTO: AllocationKeyType;
  allocationElementType: AllocationElementType;
  allocationKeyTableMappingId: string;
  allocationTableMappingId: string;
  currency: CurrencyType;
  elementId: string;
  id: string;
  unit: UnitType;
  value: string;
  asOfDate?: string;
  peTransactionIdOrPortfolioId?: string;
  allocationKeyId?: string;
};

export type CRMAllocationType = Omit<AllocationType, "currency"> & {
  portfolioName?: string;
  portfolioId?: string;
  currency: AnalyticsCurrencyType;
  versionName: string;
  isTotal?: boolean;
  prevTotal?: number;
};

export type CompanyType = {
  createdBy?: string;
  id: string;
  name: string;
  payment: boolean;
  tncApproved: boolean;
  companyType?: CompanyEnumType;
  industries: IndustryType[];
  geographies: GeographyType[];
};
export type CRMCompanyMappingType = {
  id: string;
  companyDTO: CompanyType;
  thirdPartyCompanyId?: string;
  thirdPartyCompanyName?: string;
  since: number;
  description: string;
  ebitdaRangeDTO: MonetaryRangeType;
  targetHoldSizeDTO: MonetaryRangeType;
  industries?: string[];
  geographies?: string[];
  portfolioIds?: string[];
  countries?: string[];
  investmentTypes: RelationshipInvestmentType[];
  institutionTypes: RelationshipInstitutionType[];
  structuresTypes: RelationshipStructureType[];
  note: string;
  externalId: string;
  lastMeetingDate: string;
  relationshipOwners: UserType[];
};

export type CrmDealActivity = {
  peTransactionTitle: string;
  companyToBuy?: string;
  targetDate?: string;
  peTransactionTagType?: PETransactionTagType;
  allocated?: boolean;
  comments?: string;
  peTransactionCreatorType: string;
  peTransactionCreationType: string;
  creationDate: string;
  peTransactionTypeName?: string;
  lenderActive: boolean;
  createDate: string;
  lenderAddedOn: string;
  deleted: boolean;
  userActive: boolean;
  peTransactionId: string;
  elementTagDTOs: TagType[];
};

export type CrmAllocation = {
  transactionName: string;
  currency: CurrencyType;
  unit: UnitType;
  value: string;
  asOfDate: string;
  facilities: Record<string, FacilityAllocatedValue>;
};

export type Facility = {
  name: string;
  id: string;
};

export type FacilityAllocatedValue = {
  value: string;
  currency: CurrencyType;
  unit: UnitType;
};

export type CrmUserType = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  currentActiveDeal: string;
  title?: string;
  transactionDTOs?: CrmDealActivity[];
  portfolioDTOS?: CRMPortfolio[];
};

export type FileElementType = {
  "@id"?: string;
  active: boolean;
  childFileElements: FileElementType[];
  deleted: boolean;
  elementId: string;
  level: string;
  name: string;
  createdByUserDTO: UserType;
  fileId: string;
  fileURL: string;
  isFolder: boolean;
  modifiedDate: string;
  parentFileElement: string;
  permissionDTO: PermissionDataType;
  sharedWith: string[];
  rootSharedWith?: string[];
  fileDTO?: FileType;
  fileElementType: FileElementEnumType;
  fileName: string;
  byteSize: number;
  fileElements: FileElementType[];
  response: {
    objectKey: string;
  };
  bucketName: string;
  objectKey: string;
  contentType: string;
};

export type FileType = {
  bucketName: string;
  byteSize: string;
  contentType: string;
  createdBy: string;
  fileName: string;
  fileURL: string;
  objectKey: string;
  fileId?: string;
  elementId?: string;
};

export type LenderDownLoadInfoType = Record<number, Record<number, string[]>>;

/** Team **/
export type TeamMemberType = {
  companyId: string;
  id: string;
  invitationStatus: InvitationStatusType;
  permissions: string[];
  userDTO: UserType;
};

export type InviteUserType = {
  id: string;
  invitationStatus: InvitationStatusType;
  elementType: ElementType;
  elementId: string;
  peTransactionId: string;
  invitedBy: string;
  companyId: string;
  token: string;
  admin: boolean;
  inviteLink: string;
  companyName: string;
  invitedDate: string;
  inviteType: InvitationType;
};

export type InviteRequestType = {
  approvedBy: string;
  isApproved: boolean;
  message: string;
  invitedByUserDTO: UserType;
  companyDTO: CompanyType;
  approvedByUserDTO: UserType;
  invitedUserDTO: UserType;
  approved: boolean;
};

export type deleteUserFromTeamType = {
  companyId: string;
  elementId: null;
  elementType: string;
  email: string;
};

export type TemplateType = {
  createDate: string;
  createdBy: string;
  createdByUserDTO: UserType;
  id: string;
  name: string;
};

export type CompanyModuleAccessType = {
  companyId: string;
  accessLevel: AccessLevelType;
  moduleAccessDTO: CompanyModuleType;
};

export type CompanyModuleType = Record<ModuleType, boolean>;

/** File Upload **/
export type TermsheetUploadType = {
  lenderId: string;
  sheetNumber: number;
  columnNumber: number;
  startRow: number;
  transactionId: string;
  versionId: number;
  fileDTO: FileType;
  webformId: string;
  bidMetricColNum: number;
  endRow: number;
  userId: string;

  // For Offline Transaction
  lenderIdAndExcelColMap?: LenderExcelDTOType[];

  // For Docx format
  tableNumber: number;
};

/** LoginVerification Code **/
export type VerificationCodeType = {
  code: number;
};

export type Verify2FaPhoneCodeType = {
  verified: boolean;
};

export type Verify2FaPhoneCodeLoginType = {
  otpStatus: string;
};

// Communications Type
export type UnreadCountType = Record<string, string>;

/** Admin Page Code **/

export type ActivityType = {
  activityType: NotificationActivityEnumType;
  createDate: string;
  createdFor: string;
  dataMap: Record<AttributeType, string>;
  elementId: string;
  elementType: string;
  id: string;
  message: string;
  petransactionId: string;
  transactionName: string;
};

export type InviteType = {
  admin: boolean;
  companyId: string;
  companyName: string;
  email: string;
  firstName: string;
  invitationStatus: string;
  inviteLink: string;
  invitedDate: string;
  lastName: string;
  title: string;
  systemAdminApproved: boolean;
};

/** Security page Code **/
export type SwitchType = {
  enabled: boolean;
};

export type NotificationSubscriptionType = {
  notificationType: NotificationSettingType;
  notificationTypeLabel: string;
  notificationCategory: string;
  notificationCategoryLabel: string;
  enabled: boolean;
};

// Analytics Type
export type AnalyticsType = {
  active: boolean;
  companyId: string;
  config: string;
  id: string;
};

export type AnalyticsConfigType = {
  name: string;
  type: string;
  rows: { columns: AnalyticsColumnsType[] }[];
};

export type AnalyticsFilters = "INSTITUTIONS";
export type AnalyticsColumnsType = {
  name: string;
  label: string;
  chartType: AnalyticsChartType;
  filters: Array<AnalyticsFilters>;
};

export type SearchResponseType = {
  id: string;
  transactionName: string;
  termSheetName: string;
  lenderActive: boolean | null;
  lenderCompanyName: string;
  dealTeamName: string;
  createdDate: string;
  createdByUser: string;
  industryIds: string[];
  lenderPublished: boolean;
  targetCompany: string;
  version: string;
  status: string;
  webFormId: string;
  isOffline: boolean;
  isPortfolio?: boolean;
};

export type SearchResultsResponseType = {
  results: SearchResponseType[];
  nextPageToken: string;
};

export type FiltersResponseType = {
  industries: KeyValueType[];
  companies: KeyValueType[];
  versionFilters: KeyValueType[];
  statuses: string[];
};

export type SearchDataMap = {
  metric: string;
  ask: string;
  lenderDataMap: KeyValueType;
  webFormConfigId: string;
  highlight: {
    matchedMetric: string;
  };
};

export type WebFormDetailsGroupedResponseType = {
  transactionName: string;
  termSheetName: string;
  dealTeamName: string;
  createdDate: string;
  createdByUser: string;
  version: number;
  termSheetData: SearchDataMap[];
  isOffline: boolean;
};

/** Portfolio Type **/
export type PortfolioType = {
  active: boolean;
  companyId: string;
  createDate: string;
  id: string;
  portfolioCompanyName: string;
  portfolioSectionDTOS: PortfolioSectionType[];
  funds?: string[];
  status?: boolean;
  currency?: AnalyticsCurrencyType;
  investmentDate?: string;
  summaryField?: { data: FinancialFieldDataType[] };
};

export type FinancialFieldDataType = {
  fieldType: string;
  quarter: string;
  tag: string;
  unit: UnitType;
  value: string;
  year: string;
};

export type PortfolioAllocationAndFeesType = {
  allocationTableMappingDTOList: TableDataType[];
  allocationDTOList: AllocationType[];
  allocationKeyDTOList: AllocationKeyType[];
};

export type PortfolioSectionType = {
  active: boolean;
  canDelete: boolean;
  elementId: string;
  id: string;
  portfolioSectionFieldDTOS: PortfolioSectionFieldType[];
  portfolioKeyContactDTOList: KeyContactsType[];
  privateEquityTransactionDTOList: TransactionType[];
  portfolioSectionTableVersionDTOS: PortfolioSectionTableVersionDTO[];
  capitalStructureTableFieldDTOS: CapitalStructureTableFieldDTO[];
  hedgingStructureTableFieldDTOS: HedgingDataType[];
  amortizationDTOS: AmortizationDataType[];
  portfolioFinanceDTO: PortfolioFinanceType;
  rank: string;
  sectionElementType: string;
  sectionName: string;
  sectionType: PortfolioSectionEnumType;
  reportingCurrency: string;
  allocationAndFeesDTO: PortfolioAllocationAndFeesType;
  leakageAnalysisSectionKey: string;
};

export type PortfolioSectionFieldType = {
  canDelete: boolean;
  fieldName: string;
  answer?: string;
  selectedAnswerIds?: Array<string>;
  fieldNameSystemLabel: string;
  id: string;
  portfolioSectionId: string;
  rank: string;
  required: boolean;
  portfolioId?: string;
  sectionFieldType: SectionFieldType;
  options?: PortfolioSectionFieldOptionsType[];
};

export type PortfolioSectionFieldOptionsType = {
  id: string;
  optionValue: string;
  portfolioSectionFieldId: string;
  optionValueSystemLabel?: string;
};

export type KeyContactsType = {
  companyId: string;
  companyName: string;
  emailId: string;
  id: string;
  name: string;
  portfolioId: string;
  tagId: string;
  title: string;
  userId: string;
  updated?: boolean;
};
export type FieldType = {
  label: string;
  sectionFieldType: SectionFieldType;
  options?: Array<{ optionValue: string } | undefined>;
};
export type PortfolioTransactionType = {
  createDate?: string;
  id: string;
  peTransactionId: string;
  portfolioId: string;
  transactionName: string;
};
export type CompanyTagType = {
  color: string;
  companyId: string;
  id: string;
  label: string;
};

export type PortfolioSectionTableVersionDTO = {
  id: string;
  sectionId: string;
  versionName: string;
  description: string;
  active: boolean;
  versionDate: string;
  createDate: string;
};

export type CapitalStructureTableFieldDTO = {
  id: string;
  fieldType: SectionFieldType;
  options: string;
  tag: string;
  label: string;
  rank: string;
  active: boolean;
  portfolioCompanyTableFieldId: string;
  formula?: string;
  unit?: UnitType;
  capitalStructureValues: CapitalStructureValuesType[];
};

export type CovenantTableFieldDTO = {
  id: string;
  fieldType: SectionFieldType;
  options: string;
  tag: string;
  label: string;
  rank: string;
  active: boolean;
  portfolioCompanyTableFieldId: string;
  formula?: string;
  unit?: UnitType;
  testScheduleValues: CapitalStructureValuesType[];
};

export enum CovenantTypeEnum {
  NUMERIC = "NUMERIC",
  CURRENCY = "CURRENCY",
  MULTIPLE = "MULTIPLE",
}

export enum UnderWritingType {
  LOAN = "LOAN",
  BOND = "BOND",
  BOTH = "BOTH",
  BRIDGE = "BRIDGE",
}

export type UnderWritingRowDTO = {
  id: string;
  fieldType: SectionFieldType;
  rank: string;
  canDelete: boolean;
  underWritingType: UnderWritingType;
  fieldName?: string;
  value?: string;
  unit: string;
  //testScheduleValues: CapitalStructureValuesType[];
};

export type HedgingDataType = {
  id: string;
  fieldType: SectionFieldType;
  options: string;
  tag: string;
  label: string;
  rank: string;
  active: boolean;
  portfolioCompanyTableFieldId: string;
  unit?: UnitType;
  hedgingStructureValues: HedgingValuesType[];
};

export type AmortizationDataType = {
  id: string;
  fieldType: SectionFieldType;
  tag: string;
  label: string;
  rank: string;
  active: boolean;
  portfolioCompanyTableFieldId: string;
  unit?: UnitType;
  amortizationFieldValues: AmortizationValuesType[];
};
export type CapitalStructureValuesType = {
  id: string;
  value?: string;
  rank: string;
};

export type HedgingValuesType = {
  id: string;
  value?: string;
  rank: string;
};
export type AmortizationValuesType = {
  id: string;
  value?: string;
  rank: string;
};
export type PortfolioTransactionListType = {
  createDate: string;
  createdByUserDTO: UserType;
  deleted: boolean;
  companyToBuy: string;
  peTransactionId: string;
  peTransactionNotificationCount: string;
  peTransactionTitle: string;
};

export type TransactionMappingType = {
  id: string;
  peTransactionId: string;
  transactionName: string;
  portfolioId: string;
  createDate: string;
  peTransactionTitle: string;
};

export type PortfolioTermType = {
  createDate: string;
  portfolioId: string;
  id: string;
  name: string;
  creatorName: string;
};

export type PortfolioDealTeamAskType = {
  id: string;
  styleData: UiDataType;
  dealTeamAskData?: string;
  portfolioBidMetricId: string;
};

export type PortfolioBidMetricType = {
  id: string;
  styleData: UiDataType;
  position: string;
  bidMetricData: string;
  portfolioWebformId: string;
  portfolioDealTeamAskDTO: PortfolioDealTeamAskType;
};

export type PortfolioWebFormDataType = {
  id: string;
  portfolioId: string;
  transactionId: string;
  bidMetricDTOList: PortfolioBidMetricType[];
  name: string;
};

export type QueryResultType<T> = {
  data: Array<T>;
};

export type FinanceHistoryType = {
  user: string;
  field: string;
  unit: string;
  fieldType: string;
  quarter: QuarterType;
  year: string;
  value: string;
  lastUpdated: string;
  previousValue: string | null;
};

export type PortfolioFinanceType = {
  year: string;
  fields: Array<PortfolioTableFieldType>;
};

export type PortfolioTableFieldType = {
  id: string;
  active: boolean;
  fieldType: string;
  label: string;
  portfolioCompanyTableFieldId: string;
  rank: string;
  tag: string;
  unit: string;
  formula?: string;
  rootFieldId?: string;
  options?: string;
  values: Array<PortfolioFinanceFieldValueType>;
  nestedFields?: Array<PortfolioTableFieldType>;
};

export type PortfolioFinanceFieldValueType = {
  id?: string;
  quarter: QuarterType;
  value: string;
  year: string;
};

export type DealCloudCompanyNameDTOType = {
  type: number;
  id: string;
  name: string;
  entryListId: number;
};

export type DealCloudCompanyDTOType = {
  EntryId: string;
  CompanyName: DealCloudCompanyNameDTOType;
};

export type DealCloudGetCompanyResponseDTOType = {
  totalRecords: string;
  rows: DealCloudCompanyDTOType[];
};

export type DealCloudTransactionNameDTOType = {
  type: string;
  id: string;
  name: string;
  entryListId: string;
};

export type DealCloudTransactionDTOType = {
  EntryId: string;
  Company: DealCloudCompanyNameDTOType;
  DealName: DealCloudTransactionNameDTOType;
  DescriptionThesis: string;
  NewDealDate: string;
  Status: {
    name: string;
  };
};

export type DealCloudGetTransactionResponseDTOType = {
  totalRecords: string;
  rows: DealCloudTransactionDTOType[];
};

export type SettingsConfigTypes =
  | DealCloudConfigType
  | CompanyPasswordConfigType;

export type CompanySettingConfigType<
  ValueType extends SettingsConfigTypes = any
> = {
  id?: number;
  elementId: string;
  elementType: ElementType.COMPANY;
  configType: ConfigType;
  configValue?: ValueType;
  settingType: ConfigurationSettingType;
  active?: boolean;
};

export type CompanyPasswordConfigType = {
  minPasswordLength: number;
  passwordPolicyEnabled: boolean;
  maxPasswordAge: number;
  unusablePassword: number;
};
export type MergeCompanyType = {
  toInstitution: CompanyType;
  fromInstitution: CompanyType;
};

export type DealCloudConfigType = {
  clientId: string;
  clientSecret: string;
  grantType: string;
  domain: string;
  scope: string;
};

export type NdaConfigType = {
  lenderId: string;
  ndaRequired: boolean;
  ndaFileElementId: string;
  companyId: string;
  lenderName: string;
  peTransactionId: string;
  ndaFileName: string;
  ndaConfigId: string;
  ndaText: string;
  ndaModuleConfig: string;
  oneTimeNda: boolean;
  fileElementDTOS: FileElementType[];
};

export type LenderNdaSignType = {
  id: string;
  isActive: boolean;
  userId: string;
  transactionId: string;
  lenderId: string;
  ndaAccepted: boolean;
  ndaUploadedElementId: string;
  ndaElementId: string;
  userSessionId: string;
};

export type TransactionNdaSettingDTO = {
  id: string;
  automaticNdaSetup: boolean;
  fileElementId: string;
  transactionId: string;
  fileId: string;
  ndaEnabled: boolean;
  fileName: string;
};

export type LenderExcelDTOType = {
  lenderId: string;
  excelColNum: string;
};

export type CompanyInfoType = {
  teamDTOList: TeamType[];
  portfolios: PortfolioType[];
  transactionDTOS: TransactionType[];
  companyDTO: CompanyType;
};

export type UserNdaConsentType = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  consentDate: string;
  ndaAccepted: boolean;
  lenderId: string;
  lenderName: string;
};

export type WebhookEventType = {
  id: string;
  label: string;
  name: string;
  description: string;
  versions: Array<WebhookVersionType>;
};

export type WebhookVersionType = {
  id: string;
  example: any;
  metadata: any;
  version: string;
};
export type SubscriptionType = {
  id: string;
  url: string;
  eventTypes: Array<WebhookEventType>;
  headers: string;
  active: boolean;
  enabled: boolean;
};

export type ThirdPartyCompanyType = {
  id: string;
  companyId: string;
  thirdPartyCompanyId: string;
  thirdPartyIntegrationType: ThirdPartyIntegrationType;
  elementId: string;
  dealCloudCompanyName: string;
  companyName: string;
  createDate: string;
  createdBy: string;
  modifiedBy: string;
  modifiedDate: string;
  createdByUserDTO: UserType;
  modifiedByUserDTO: UserType;
};

export type TagType = {
  id: string;
  color: string;
  companyId: string;
  label: string;
  description: string;
  createDate: string;
  modifiedDate: string;
  rank: string;
  default: boolean;
  smartActions: SmartTagActionType[];
};

export type CompareColumnsDataMapType = {
  date: string;
  label: string;
  data: Record<number, UiDataType>;
};
export type CompareColumnsType = {
  metrics: CompareColumnsDataMapType;
  base: CompareColumnsDataMapType;
  columns: CompareColumnsDataMapType[];
};

export type ModuleAccessLevelType = {
  level: AccessLevelType;
  config: CompanyModuleType;
};

export type MonetaryRangeType = {
  min?: number;
  max?: number;
  currency: CurrencyType;
  id: string;
  elementId: string;
  elementType: ElementType;
  monetaryType: MonetaryType;
  unit: UnitType;
};

export type CRMFilterType = {
  from?: string;
  to?: string;
  fxRateTargetCurrency?: AnalyticsCurrencyType;
  fxRateDate?: string;
  crmElementType?: ElementType;
};

export type CRMPortfolio = {
  portfolioName: string;
  investmentDate: string;
  exitDate: string;
  companyName: string;
  transactionType: string;
  funds: string[];
  status: string;
};

export type CountryType = {
  id: string;
  name: string;
  code: string;
  emoji: string;
  dialCode: string;
  unicode: string;
  imageUrl: string;
};

export type UserTokenType = {
  token: string;
  expiryInSeconds: string;
};

export type SummaryTableDataType = {
  allocationKeyDTO: {
    id: string;
    keyName: string;
    canDelete: boolean;
  };
  companyDTO: {
    id: string;
    name: string;
    payment: boolean;
  };
  valueMap: {
    DOLLAR: string;
    EURO: string;
    POUND: string;
    KRONA: string;
    KRONE: string;
    KRONER: string;
    AUSTRALIAN_DOLLAR: string;
    CANADIAN_DOLLAR: string;
    FRANC: string;
    SINGAPORE_DOLLAR: string;
    HONG_KONG_DOLLAR: string;
    YEN: string;
  };
  totalConvertedAmount: {
    DOLLAR: string;
    EURO: string;
    POUND: string;
    KRONA: string;
    KRONE: string;
    KRONER: string;
    AUSTRALIAN_DOLLAR: string;
    CANADIAN_DOLLAR: string;
    FRANC: string;
    SINGAPORE_DOLLAR: string;
    HONG_KONG_DOLLAR: string;
    YEN: string;
  };
};

export type AllocationSummaryWrapper = {
  allocationSummaryDTOS: SummaryTableDataType[];
  versions: TableDataType[];
  fxRateMap: Record<CurrencyType, Record<CurrencyType, any>>;
};

export type TransactionNotificationType = {
  id: string;
  userId: string;
  elementType: ElementType;
  elementId: string;
  enabled: boolean;
};
export type DebtType = {
  id: string;
  name: string;
  active: boolean;
};

export type CompareSheetType = {
  transactionId: string;
  transactionName: string;
  termSheetName: string;
  dealTeamName: string;
  targetCompany: string;
  createdByUser: string;
  sheetId: string;
  createdDate: string;
  version: number;
  isOffline: boolean;
  lenderKeys: string[];
  ids: string[];
  isPortfolio: boolean;
  createdByCompanyName: string;
};

export type CompareScreenType = {
  sheets: CompareSheetType[];
  sheetsDataByRow: CompareScreenRowType[];
  matchedTokens: string[];
};

export type CompareScreenRowType = Record<string, SearchDataMap>;

export type KeyValueNumberType = { [k: string]: number };

export type LeakageTemplateTypeSelectEnums =
  | LeakageDataTypes.DECISION_ENUM
  | LeakageDataTypes.MONTHS_RANGE_ENUM
  | LeakageDataTypes.MARGIN_ENUM
  | LeakageDataTypes.COLOR_ENUM
  | LeakageDataTypes.BOOLEAN_ENUM;

export type LeakageTemplate = {
  id: string;
  name: string;
  templateType: LeakageTemplateType;
  leakageTemplateSections: LeakageTemplateSection[];
  enumMap?: Record<LeakageTemplateTypeSelectEnums, string[]>;
  companyId?: string;
};

export type LeakageTemplateSection = {
  displayTitle?: string;
  sectionId?: string;
  leakageDataType?: LeakageDataTypes;
  ownerType?: LeakageTemplateType;
  ownerCompanyId?: string;
  hide?: boolean;
  leakageTemplateRows: LeakageTemplateRows[];
};

export type LeakageTemplateRows = {
  keyId: string;
  leakageDataType: LeakageDataTypes;
  displayTitle: string;
  css?: string;
  commentAllowed: boolean;
  leakageTemplateRows?: LeakageTemplateRows[];
  hide?: boolean;
  ownerType?: LeakageTemplateType;
  ownerCompanyId?: string;
  tempId?: string;
};

export type LeakageData = {
  id?: string;
  templateId: string;
  elementId: string;
  elementType: ElementType;
  templateVersion: string;
  leakageElementKeyDataDTOMap: Record<string, LeakageDataRow>;
};

export type LeakageDataRow = {
  templateId: string;
  elementId: string;
  elementType: ElementType;
  keyId: string;
  value?: string;
  comments: string;
  currency: CurrencyType;
  unit: UnitType;
};

export type RelationShipDashboardFilterType = {
  structuresTypes?: string[];
  institutionTypes?: string[];
  searchKey?: string;
  fxRateTargetCurrency?: AnalyticsCurrencyType;
  fxRateDate?: string;
  geographies?: string[];
  industries?: string[];
  portfolios?: string[];
};

export type CrmAllocationResponseType = {
  totalExposure: number;
  allocationDTOList: CRMAllocationType[];
  totalExposureForCompany: number;
};

export type RelationshipExposureResponse = Record<
  ElementType.CRM | ElementType.SPONSOR_COVERAGE,
  {
    overallExposure: number;
    exposuresByCompany: Record<string, number>;
  }
>;
